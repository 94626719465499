import React from "react";
import styled from "styled-components";
import HeroButton from "../components/HeroButton";
import useInViewAnimation from "hooks/useInViewAnimation";
import { motion } from "framer-motion";
import { TiArrowDownThick } from "react-icons/ti";

function Last() {
    const variant = {
        visible: { opacity: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0 },
    };
    const [ref, control] = useInViewAnimation(0);
    return (
                <Container ref={ref} animate={control} variants={variant} initial="hidden">
                    <Title>지금 바로 계약하기 </Title>
                    <TiArrowDownThick style={{ color: "#303030", fontSize: "48px", marginTop: "30px", marginBottom: "-30px"}} />
                    <HeroButton text={"Click !"} width={"180px"}/>
                </Container>
    );
}

export default Last;

const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  padding: 150px 0;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  align-items: center;
  color: #303030;
  font-size: 50px;
  font-weight: 700;
  gap: 10px;
  letter-spacing: -0.88px;
  line-height: 30px;
  margin-bottom: 8px;
  text-align:center;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
