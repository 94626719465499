import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from 'axios';
import PropTypes from "prop-types";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setCurrentUser({token});
        }
    }, []);

    const login = async (email, password) => {
        try {
            const response = await axios.post('/SignIn/admin', {
                memberName: email,
                memberEmail: password
            });
            if (response.status === 200 && response.data.result === true) {
                // 로그인 성공 처리
                localStorage.setItem('token', response.data.data); // 예시
                // setCurrentUser({ ...response.data.user });
                setCurrentUser({email})
                return true;
                // 여기서 필요한 추가적인 성공 처리
            } else {
                setCurrentUser(null);
                return false;
                // 로그인 실패 처리
            }
        } catch (error) {
            console.error("Login error:", error);
            throw error;
            // 오류 처리
        }
    };


    const logout = () => {
        localStorage.removeItem('token'); // 토큰 제거
        setCurrentUser(null); // 사용자 상태 초기화
    };

    const value = {
        currentUser,
        login,
        logout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};