import React from 'react';

function TermsOfServiceContent() {
    const serviceText = `제1조 (목적)
    본 약관은 텐마일즈(이하 "회사")가 제공하는 비상주 사무실 임대 및 관련 서비스 텐세컨즈(이하 "서비스")의 이용 조건 및 절차, 이용자와 회사의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.

    제2조 (용어의 정의)

    본 약관에서 사용하는 용어는 다음과 같이 정의합니다.

① 서비스: '회사’의 브랜드를 사용하는 온라인과 오프라인을 포함하는 모든 상품 및 서비스를 말하며, 회사가 제공한 API를 이용하여 제3자가 개발 또는 구축한 프로그램이나 서비스를 통하여 고객 또는 회원에게 제공되는 경우를 포함합니다.

② 사이트: ‘회사’가 운영하는 브랜드의 사이트를 말합니다.

③ 이용자: 본 약관에 따라 회사가 제공하는 서비스를 이용하는 모든 사람을 말합니다.
④ 계약: 서비스 이용을 위해 회사와 이용자 간에 체결하는 모든 계약을 말합니다.
⑤ 비상주 사무실: 회사가 제공하는 사무 공간을 말합니다.

    제3조 (약관의 효력 및 개정)

① 본 약관은 그 내용을 회사가 사이트에 게시하고, 고객이 회사의 서비스를 이용함으로써 그 효력이 발생합니다.
② 본 약관은 수시로 개정할 수 있으며 약관을 개정하고자 할 경우 회사는 개정된 약관을 적용하고자 하는 날(이하 “효력 발생일”이라 함)로부터 최소 14일 이전에 약관이 개정된다는 사실과 개정된 내용 등을 아래 규정된 방법 중 1가지 이상의 방법으로 이용자에게 통지합니다. 다만, 이용자에게 불리하게 약관 내용이 변경되는 경우에는 최소한 30일 이상의 사전 유예 기간을 두고 고지합니다.

    가. 이메일(E-mail) 통보

    나. ’회사’ 멤버십 앱 Push, 공지 사항

    다. 휴대전화 메시지(SMS, LMS 등)

    라. 서비스 사이트 또는 앱 공지사항 내 고지

    마. 지점 내 게시

    바. 기타 계약 진행 시, 이용자가 제공한 연락처 정보 등을 이용한 전화 안내 등의 방법

③ 회사가 이메일(E-mail) 통보의 방법으로 본 약관이 개정된 사실 및 개정된 내용을 이용자에게 고지하는 경우에는 계약서 작성 시 기재한 이메일(E-mail) 주소로 통보하며 이 경우, 당사가 적법한 통보를 완료한 것으로 봅니다.
④ 본 규정에 의하여 개정된 약관은 원칙적으로 그 효력 발생일로부터 장래를 향하여 유효합니다.
⑤ 본 약관의 개정과 관련하여 이의가 있는 이용자는 중도 계약 해지를 할 수 있으며 개정된 약관의 효력 발생일까지 계약 해지 하지 않거나 회사에 이의를 제기하지 않는 경우 변경된 약관에 동의한 것으로 봅니다.
⑥ 본 규정의 통지 방법 및 통지의 효력은 본 약관의 각 조항에서 규정하는 개별적인 또는 전체적인 통지의 경우에 이를 준용합니다.

    제4조 (약관 외 준칙)

① 회사가 제공하는 서비스의 구체적 내용에 따라 개별 서비스에 대한 약관 및 운영 정책 (이하 “운영 정책 등”)을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 운영 정책 등이 우선하여 적용됩니다
② 본 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영 정책 등 및 관계 법령 또는 상 관례에 따릅니다.

    제5조 (서비스 개요)
① 회사는 다음과 같은 서비스를 제공합니다.
        가. 비상주 사무실 임대 서비스
    나. 계약서 작성 및 관리 서비스

    제6조 (이용 계약의 체결)
① 서비스 이용 계약은 이용자가 본 약관의 내용에 동의하고, 회사가 정한 절차에 따라 이용 신청을 하면 회사의 이용 승낙으로 성립합니다.
② 회사는 이용자의 신청에 대해 서비스 이용을 승낙할 수 있으며, 다음 각 호에 해당하는 경우 승낙을 거절할 수 있습니다.
        가. 신청 내용에 허위, 누락, 오기가 있는 경우
    나. 기타 회사가 정한 이용 신청 요건이 충족되지 않은 경우
③ 회사는 이용자의 신청을 승낙한 경우 이용자에게 서비스 이용에 필요한 정보를 제공합니다.

    제7조 (이용자의 의무)
① 이용자는 관계 법령, 본 약관의 규정, 이용 안내 및 서비스 관련 공지 사항 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
② 이용자는 서비스 이용과 관련하여 다음의 행위를 하여서는 안 됩니다.
        가. 서비스 신청 또는 변경 시 허위 내용의 등록
    나. 타인의 정보 도용
    다. 회사가 게시한 정보의 변경
    라. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
    마. 회사 및 기타 제3자의 지식재산권 침해
    바. 회사 및 기타 제3자의 명예를 손상하거나 업무를 방해하는 행위
    사. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
③ 이용자가 전항의 의무를 위반할 경우, 회사는 이용자에게 경고 또는 서비스 이용 제한 조치를 할 수 있으며, 심각한 경우 계약을 해지할 수 있습니다.

    제8조(이용자에 대한 통지)
① 이용자에 대한 통지를 하는 경우 계약 시 등록한 이메일(E-mail)주소 또는 Push 메시지 등으로 할 수 있습니다.
② 회사는 불특정 다수 이용자에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다

    제9조 (서비스 이용료 및 결제)
① 서비스의 이용료 및 결제 방법은 서비스 별 공지 사항 또는 계약 과정에서 별도로 명시합니다.
② 이용자가 서비스 이용료를 지불하지 않을 경우, 회사는 서비스 제공을 중단할 수 있으며, 이용자는 지불하지 않은 이용료 및 연체료를 지불해야 합니다.
③ 회사는 서비스 이용료를 변경할 경우 변경 내용을 이용자의 이메일 주소 또는 서비스 내 메시지 기능을 통해 이용자에게 통지합니다.
④ 이용자는 변경된 서비스 이용료에 동의하지 않을 경우 서비스 이용을 중단할 수 있습니다.

    제10조 (서비스의 이용시간)
① 회사가 제공하는 서비스의 이용은 연중무휴, 1일 24시간을 원칙으로 합니다.

② 다만, 지점 이용 시간은 각 지점의 특성상 유동적일 수 있으며, 업무상이나 기술상의 이유로 서비스가 일시 중지될 수 있고, 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다.

③ 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.

    제11조 (서비스의 변경 및 중지)
① 회사는 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
        가. 서비스 설비의 보수 등 공사로 인한 부득이한 경우
    나. 이용자가 회사의 영업 활동을 방해하는 경우
    다. 정전, 제반 설비의 장애 또는 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
    라. 기타 천재지변, 국가 비상 사태 등 불가항력적 사유가 있는 경우
③ 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경 사유, 변경될 서비스의 내용 및 제공 일자 등은 그 변경 전에 해당 서비스 내 “공지사항” 화면 등 이용자가 충분히 인지할 수 있는 방법으로 게시합니다.
④ 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
⑤ 이용자는 수시로 제 ② 항과 제 ③ 항에 의한 서비스 사이트를 확인하여야 하며, 공지 사항 미확인으로 인해 발생하는 이용자의 피해에 대해서 회사는 책임을 지지 않습니다. 다만, 유료 서비스의 변경 등 이용자에게 중대하고 명백한 피해가 예상되는 경우에는 본 약관에서 정한 방법으로 이용자에게 통지합니다.
⑥ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법에 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다.

    제12조 (계약 해지 및 이용 제한)
① 이용자는 언제든지 서비스 이용을 해지할 수 있으며, 해지 시 회사는 관련 법령 및 개인정보 처리 방침에 따라 이용자의 정보를 보호합니다.
② 회사는 이용자가 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 사전 통보 없이 계약을 해지하거나 서비스 이용을 제한할 수 있습니다.
③ 회사가 계약을 해지하거나 서비스 이용을 제한하는 경우, 이용자는 회사에 대해 손해배상을 청구할 수 없습니다.

    제13조 (정보의 제공 및 광고의 게재)
① 회사는 서비스를 운영함에 있어 회사의 서비스 관련 각종 정보 및 광고를 사이트와 ‘회사’ 멤버십을 포함한 서비스 화면에 게재하거나 SMS/MMS 및 스마트폰 Push 알람 서비스로 제공 받는데 동의한 경우 회사는 SMS/MMS 및 스마트폰 Push 알람 서비스로 관련 정보를 제공할 수 있습니다.
② 서비스 화면 및 서비스 사이트 등에 게재되어 있는 광고주의 판촉 활동에 이용자가 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
③ 이용자는 관련 법에 따른 거래 관련 정보 및 고객 문의 등에 대한 답변 등을 제외하고는 언제든지 수신 거절을 할 수 있습니다.
④ 이용자는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.

    제14조 (“회사“의 의무)
① 회사는 관련 법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 안정적인 서비스를 제공하기 위해 최선을 다하여 노력합니다.
② 회사는 서비스 제공과 관련하여 취득한 이용자의 신상 정보를 승낙 없이 제 3자에게 누설, 배포하지 않습니다. 다만, 관계 법령에 의한 수사 상의 목적으로 관계 기간으로부터 요구 받은 경우나 방송통신심의위원회의 요청이 있는 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.
③ 회사는 서비스와 관련한 이용자의 불만 사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 이메일(E-mail) 등을 통하여 동 이용자에게 통지합니다.
④ 회사가 제공하는 서비스로 인하여 이용자에게 손해가 발생한 경우, 그러한 손해가 회사의 고의나 중과실에 기해 발생한 경우에 한하여 회사에서 책임을 부담하며, 그 책임의 범위는 통상 손해에 한합니다.
⑤ 회사는 정보통신망 이용촉진 및 정보 보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.

    제 15조 (알람 서비스에 대한 회사의 의무와 책임)
① 회사는 이용자에게 SMS, 이메일, Push 알람, 카카오톡 알림톡을 보낼 수 있습니다.
② 회사는 회원가입, 주문안내, 배송안내 등 비 광고성 정보를 카카오톡 알림톡으로 알려드립니다. 만약 알림톡 수신이 불가능하거나 수신 차단하신 경우에는 일반 문자 메시지로 보내드립니다. 카카오톡 알림톡을 통해 안내되는 정보를 와이파이가 아닌 이동통신망으로 이용할 경우, 알림톡 수신 중 데이터 요금이 발생할 수 있습니다. 카카오톡을 통해 발송되는 알림톡 수신을 원치 않으시는 이용자는 반드시 알림톡을 차단하여 주시기 바랍니다.

    제16조 (저작권 등의 귀속 및 이용 제한)
① 회사가 작성한 저작물에 대한 소유권, 저작권, 특허권, 상표권 기타 지적재산권은 회사에 귀속합니다.
② 이용자는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
③ 이용자는 서비스의 이용 권한, 기타 이용 계약 상 지위를 타인에게 양도, 증여할 수 없습니다.

    제17조 (면책 조항)
① 회사는 천재지변 또는 불가항력적인 사유로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
② 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
③ 회사는 이용자가 서비스를 통해 얻은 정보 등으로 인해 발생한 손해에 대하여 책임을 지지 않습니다.
④ 회사는 서비스 제공에 필요한 범위 내에서 이용자의 정보를 수집하고 이용하며, 이용자의 동의 없이 제3자에게 제공하지 않습니다.
⑤ 회사는 이용자의 정보를 보호하기 위해 적절한 보안 조치를 취하고 있습니다.

    제18조 (손해배상)
① 이용자가 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 본 약관을 위반한 이용자 회사에 발생하는 모든 손해를 배상하여야 합니다.
② 이용자가 서비스를 이용함에 있어 행한 불법 행위나 본 약관 위반 행위로 인하여 회사가 당해 이용자 이외의 제 3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 이용자는 자신의 책임과 비용으로 회사를 면책 시켜야 하며, 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.

    제19조 (분쟁해결)
    회사와 이용자는 서비스 이용과 관련하여 발생한 분쟁을 원만하게 해결하기 위해 필요한 모든 노력을 합니다.
        분쟁이 해결되지 않을 경우, 회사의 소재지를 관할하는 법원을 관할 법원으로 합니다.

    제20조 (관할법원)
① 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이 발생한 경우, 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
② 본 조 제 1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 민사소송법상의 관할법원에 소를 제기 할 수 있습니다.

    제21조 (기타)
    본 약관에 명시되지 않은 사항은 관계 법령에 따라 해석합니다.
        본 약관은 2024년 03월 04일부터 시행됩니다.`;
    return (
        <div>
            {serviceText}
        </div>
    );
}

export default TermsOfServiceContent;