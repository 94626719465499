/* eslint react/prop-types: 0 */

import { motion } from "framer-motion";
import styled from "styled-components";

function WhoCard({ img, alt, title, desc, animate, variants }) {
  return (
    <Container animate={animate} variants={variants}>
      <Img src={img} alt={alt} />
      <Texts>
        <Title>{title}</Title>
        <Desc>{desc}</Desc>
      </Texts>
    </Container>
  );
}

export default WhoCard;
// const Container = styled(motion.div)`
//   width: 100%;
//   height: 120px;
//   background: #fff;
//   border-radius: 20px;
//   box-shadow: 0 3.84102px 28.80766px 0 rgba(98, 100, 108, 0.06);
//   display: flex;
//   gap: 16px;
//   justify-content: start;
//   padding: 30px;
//   @media (max-width: 768px) {
//     width: 75%;
//   }
// `;

const Container = styled(motion.div)`
  width: 100%; 
  height: 150px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3.84102px 28.80766px 0 rgba(98, 100, 108, 0.06);
  display: flex;
  gap: 16px;
  justify-content: start;
  padding: 30px;
  margin: auto; /* 부모 컨테이너가 flex가 아닐 경우 가운데 정렬을 위해 추가 */
  align-items: center;
  @media (max-width: 1100px) {
    width: 75%;
    height: 120px;
  }
`;
const Img = styled.img`
  width: 50px;
  height: 50px;
`;
const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
`;
const Title = styled.p`
  color: #303030;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.875px;
  line-height: 38px;
  word-break: keep-all;
`;
const Desc = styled.p`
  color: black;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.875px;
  line-height: 30px;
  word-break: keep-all;
  white-space: pre-wrap;
  @media (max-width: 768px) {
    font-size: 16px; 
  }
`;
