import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import Chart from './sections/Chart';
// import Deposits from './sections/Deposits';
import Orders from './sections/Orders';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
    const navigate = useNavigate();

    const handleCreateFreeContract = () => {
        navigate('/pages/landing-pages/contact-us', { state: { contractStatus: '무상'}});
    }

    return (
        <ThemeProvider theme={defaultTheme}>

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Button variant="contained" color="primary" onClick={handleCreateFreeContract}>
                        무상 계약서 작성
                    </Button>
                    <Container sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            {/*<Grid item xs={12} md={8} lg={9}>*/}
                            {/*    <Paper*/}
                            {/*        sx={{*/}
                            {/*            p: 2,*/}
                            {/*            display: 'flex',*/}
                            {/*            flexDirection: 'column',*/}
                            {/*            height: 240,*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <Chart />*/}
                            {/*    </Paper>*/}
                            {/*</Grid>*/}
                            {/* Recent Deposits */}
                            {/*<Grid item xs={12} md={4} lg={3}>*/}
                            {/*    <Paper*/}
                            {/*        sx={{*/}
                            {/*            p: 2,*/}
                            {/*            display: 'flex',*/}
                            {/*            flexDirection: 'column',*/}
                            {/*            height: 240,*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <Deposits />*/}
                            {/*    </Paper>*/}
                            {/*</Grid>*/}
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <Orders />
                                </Paper>
                            </Grid>
                        </Grid>
                        {/*<Copyright sx={{ pt: 4 }} />*/}
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}