// /*
// =========================================================
// * Material Kit 2 React - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/material-kit-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
//  =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */
//
// // react-router-dom components
// import { Link } from "react-router-dom";
//
// // prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";
//
// // @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
//
// // Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
//
// function DefaultFooter({ content }) {
//   const { brand, menus, copyright } = content;
//
//   return (
//     <MKBox component="footer">
//       <Container>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
//             <MKBox>
//               <Link to={brand.route}>
//                 <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} />
//               </Link>
//               <MKTypography variant="h6">{brand.name}</MKTypography>
//             </MKBox>
//           </Grid>
//           {menus.map(({ name: title, items }) => (
//             <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
//               <MKTypography
//                 display="block"
//                 variant="button"
//                 fontWeight="bold"
//                 textTransform="capitalize"
//                 mb={1}
//               >
//                 {title}
//               </MKTypography>
//               <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
//                 {items.map(({ name, route, href }) => (
//                   <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
//                     {href ? (
//                       <MKTypography
//                         component="a"
//                         href={href}
//                         target="_blank"
//                         rel="noreferrer"
//                         variant="button"
//                         fontWeight="regular"
//                         textTransform="capitalize"
//                       >
//                         {name}
//                       </MKTypography>
//                     ) : (
//                       <MKTypography
//                         component={Link}
//                         to={route}
//                         variant="button"
//                         fontWeight="regular"
//                         textTransform="capitalize"
//                       >
//                         {name}
//                       </MKTypography>
//                     )}
//                   </MKBox>
//                 ))}
//               </MKBox>
//             </Grid>
//           ))}
//           <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
//             {copyright}
//           </Grid>
//         </Grid>
//       </Container>
//     </MKBox>
//   );
// }
//
// // Typechecking props for the DefaultFooter
// DefaultFooter.propTypes = {
//   content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
// };
//
// export default DefaultFooter;

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import logo from "assets/images/tenseconds2.png";
import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from '@mui/material/IconButton';

function DefaultFooter() {
  return (
      <MKBox
          component="footer"
          sx={{
            backgroundColor: "grey.900", // 어두운 배경색 설정
            p: 5, // 패딩 추가
          }}
      >
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item lg={6} sx={{ textAlign: "left", my: 5 }}>
              <MKBox component="img" src={logo} alt="TenSeconds Logo" maxWidth="16rem"  />
              <MKTypography variant="body2" color="white">
                주식회사 텐마일즈
              </MKTypography>
              <MKTypography variant="body2" color="white">
                주소 : 경기도 수원시 팔달구 인계동 1121-2 우성타워 9층
              </MKTypography>
              <MKTypography variant="body2" color="white">
                사업자등록번호 : 815-81-00828
              </MKTypography>
              <MKTypography variant="body2" color="white">
                통신판매업신고증 : 제2020-수원영통-0209호
              </MKTypography>
              <MKTypography variant="body2" color="white">
                대표이사 : 조훈상
              </MKTypography>
              <MKTypography variant="body2" color="white">
                이메일 : tenmiles0403@gmail.com
              </MKTypography>
              <MKTypography variant="body2" color="white" mt={10}>
                © 2024 TenSeconds Inc. All rights reserved.
              </MKTypography>
            </Grid>
            <Grid item lg={6} sx={{ display: "flex", justifyItems: "flex-end",alignItems:"center", my:5}}>
            {/*<Grid item xs={12} lg={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", my: 5 }}>*/}
              <div style={{ display: "flex", justifyContent: "flex-end",alignItems:"center", width: "100%" }}>
              <MKTypography variant="body1" color="white" sx={{ mr: 2 }}>
                온라인 셀링 정보를 더 알고 싶다면
              </MKTypography>
              <IconButton
                  sx={{fontSize: "80px"}}
                  component="a"
                  href="https://www.youtube.com/@octopusman"
                  color="inherit"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="옥토퍼스맨 YouTube 채널 바로가기"
              >
                <YouTubeIcon sx={{ color: "red", fontSize: 30 }} />
              </IconButton>
              </div>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  );
}

export default DefaultFooter;
