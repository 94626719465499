import { toast } from "react-toastify";
import { toastConfig } from "./toastConfig";

export const showSuccessToast = (message, options = {}) => {
    toast.success(message, { ...toastConfig, ...options });
};

export const showErrorToast = (message) => {
    toast.error(message, toastConfig);
};

export const showInfoToast = (message) => {
    toast.info(message, toastConfig);
};