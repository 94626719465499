/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ChannelIOComponent from "pages/New/components/ChannelIOComponent";

// Material Kit 2 React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import { AuthProvider } from "./AuthContext";
import PrivateRoute from "./PrivateRoute";
import DashBoard from "layouts/pages/landing-pages/dashboard";

// raact-toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Page from "pages/New/Page";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Page />} />
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route index element={<DashBoard />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <ToastContainer
          position="top-center"
          style={{ width: "100%", maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}
        />
        {/*<ToastContainer />*/}
          <ChannelIOComponent></ChannelIOComponent>
      </ThemeProvider>
    </AuthProvider>
  );
}

// import React, { useEffect } from "react"; // useEffect 추가
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
//
// import PropTypes from 'prop-types'; // PropTypes import
//
// // Import components
// import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";
// import { AuthProvider, useAuth } from "./AuthContext";
// import DashBoard from "layouts/pages/landing-pages/dashboard";
// import SignIn from "layouts/pages/authentication/sign-in"; // 가정: AdminLogin 컴포넌트가 로그인 페이지임
//
// // RequireAuth 컴포넌트 정의
// const RequireAuth = ({ children }) => {
//     const { currentUser } = useAuth();
//     return currentUser ? children : <Navigate to="/admin" replace />;
// };
//
// RequireAuth.propTypes = {
//     children: PropTypes.node.isRequired,
// };
//
//
// export default function App() {
//     const { pathname } = useLocation();
//
//     useEffect(() => {
//         document.documentElement.scrollTop = 0;
//         document.scrollingElement.scrollTop = 0;
//     }, [pathname]);
//
//     return (
//         <AuthProvider>
//             <ThemeProvider theme={theme}>
//                 <CssBaseline />
//                 <Routes>
//                     <Route path="/" element={<Presentation />} />
//                     <Route path="/admin" element={<SignIn />} />
//                     <Route
//                         path="/dashboard"
//                         element={
//                             <RequireAuth>
//                                 <DashBoard />
//                             </RequireAuth>
//                         }
//                     />
//                     {/* 다른 라우트들이 필요하다면 여기에 추가 */}
//                     <Route path="*" element={<Navigate to="/" />} />
//                 </Routes>
//                 <ToastContainer position="top-center" style={{width:"100%", maxWidth: "500px", marginLeft: "auto", marginRight: "auto"}}/>
//             </ThemeProvider>
//         </AuthProvider>
//     );
// }
//
// // function PrivateRoute(){
// //     const{currentUser} = useAuth();
// //     return currentUser ? <DashBoard /> : <Navigate to="/admin" />;
// // }
