/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
// import axios from 'axios';
import { useAuth } from 'AuthContext';

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function SignInBasic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  // 이메일과 비밀번호 입력 처리 함수
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);


    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await login(email, password); // AuthContext의 login 함수 호출
            // navigate('/pages/landing-pages/dashboard'); // 성공 시 리디렉션
            navigate('/dashboard'); // 성공 시 리디렉션
        } catch (error) {
            // 로그인 실패 또는 오류 처리
            // navigate('/error', { state: { message: error.message }});
            navigate('/');
        }
    };


    // const handleLogin = async (event) => {
    //     event.preventDefault();
    //
    //     try {
    //         const response = await axios.post('/SignIn/admin', {
    //             memberName: email,
    //             memberEmail: password
    //         });
    //
    //         // 서버 응답을 기반으로 로그인 성공 여부 확인
    //         if (response.data && response.data.result === true) {
    //             // 로그인 성공: 대시보드로 이동
    //             navigate('/pages/landing-pages/DashBoard');
    //         } else {
    //             // 로그인 실패: 로그인 페이지 또는 오류 페이지로 이동
    //             // navigate('/login', { state: { message: response.data.message }});
    //         }
    //     } catch (error) {
    //         // 요청 실패: 오류 페이지 또는 경고 메시지 표시
    //         // navigate('/error', { state: { message: error.message }});
    //     }
    // };

  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  관리자 로그인
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleLogin}>
                  <MKBox mb={2}>
                    <MKInput label="Email" fullWidth value={email} onChange={handleEmailChange}/>
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="password" label="Password" fullWidth value={password} onChange={handlePasswordChange}/>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" ml={-1}>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton type="submit" variant="gradient" color="info" fullWidth>
                      sign in
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInBasic;
