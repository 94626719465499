import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Checkbox, Button, Box } from '@mui/material';
import DocumentModal from './DocumentModal';
import PrivacyPolicyContent from './PrivacyPolicyContent';
import TermsOfServiceContent from './TermsOfServiceContent';
import PropTypes from 'prop-types';

function AgreementForm({ onAgreementChange }) {
    // 동의 상태
    const [isAgreedPrivacy, setIsAgreedPrivacy] = useState(false);
    const [isAgreedTerms, setIsAgreedTerms] = useState(false);

    // 모달 열림 상태
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
    const [openTermsOfService, setOpenTermsOfService] = useState(false);

    useEffect(()=>{
        onAgreementChange(isAgreedPrivacy && isAgreedTerms);
    }, [isAgreedPrivacy, isAgreedTerms, onAgreementChange]);

    // 동의 상태 핸들러
    const handleAgreementChange = (setter) => (event) => {
        setter(event.target.checked);
    };

    // 모달 핸들러
    const handleOpenModal = (setOpen) => {
        setOpen(true);
    };
    const handleCloseModal = (setOpen) => {
        setOpen(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FormControlLabel
                        control={<Checkbox checked={isAgreedPrivacy} onChange={handleAgreementChange(setIsAgreedPrivacy)} />}
                        label="개인정보 처리방침에 동의합니다. (필수)"
                    />
                    <Button onClick={() => handleOpenModal(setOpenPrivacyPolicy)} variant="text" sx={{ textTransform: 'none' }}>
                        약관보기
                    </Button>
                </Box>
                <DocumentModal
                    open={openPrivacyPolicy}
                    handleClose={() => handleCloseModal(setOpenPrivacyPolicy)}
                    title="개인정보 처리방침"
                    content={<PrivacyPolicyContent />}
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FormControlLabel
                        control={<Checkbox checked={isAgreedTerms} onChange={handleAgreementChange(setIsAgreedTerms)} />}
                        label="서비스 이용 약관에 동의합니다. (필수)"
                    />
                    <Button onClick={() => handleOpenModal(setOpenTermsOfService)} variant="text" sx={{ textTransform: 'none' }}>
                        약관보기
                    </Button>
                </Box>
                <DocumentModal
                    open={openTermsOfService}
                    handleClose={() => handleCloseModal(setOpenTermsOfService)}
                    title="서비스 이용 약관"
                    content={<TermsOfServiceContent />}
                />
            </Grid>
        </Grid>
    );
}

AgreementForm.propTypes={
    onAgreementChange: PropTypes.func.isRequired,
};

export default AgreementForm;