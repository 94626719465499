import React, {Suspense} from "react";
import styled, { keyframes } from "styled-components";
import AdvantageCard from "../components/AdvantageCard";
const LazyVideo = React.lazy(() => import('../components/LazyVideo'));

function Advantages() {
  return (
    <Container>
      <Content>
        <Title>비상주 사무실이<br /> <UnderlineText>자가 소유인지</UnderlineText> 확인하셨나요? </Title>
        <AdvantageCard>
          남의 건물에 사무실을 임대하여 운영하는 소규모 영세업체의 경우<br />
          계약 후 갑작스런 운영 종료로 사업장 주소지를 잃거나, 임대료를 떼이는 피해가 번번히 일어나고 있습니다.
                        
            <br /><br />            비상주 사무실이 소재하는 건물은 저희가 <strong>‘직접’ 매입</strong>하여 운영하고 있으며, <br />저희와 직접 계약하시는 서비스 입니다.<br />
          임차인이 재임대하는 전대차 계약이 아니며, 중간 계약만 진행하는 중개 업체가 아님을 알려드립니다.
        </AdvantageCard>
        <Title><UnderlineText>월 9천원 !</UnderlineText> <br /> 대한민국 ‘진짜’ 최저가 도전</Title>
        <AdvantageCard>
          텐세컨즈는 주 수익원이 아닌 법인이 기존에 가지고 있는 <br />공유사무실을 이용하여 온라인 셀러들을 지원하는 서비스 입니다. <br />
          그리고 자동 계약서 작성 기능과 최소한의 우편물 확인 서비스로 비용과 가격을 대폭 낮추었습니다.
        </AdvantageCard>
        <Title>눈 앞에서 <UnderlineText>실시간</UnderlineText>으로 완성되는 계약서</Title>
        <AdvantageCard>
         간단한 입력과 선택만으로 24시간 쉽고, 편리한 임대차계약서를 직접 작성합니다.
        </AdvantageCard>
        <VideoContainer>
          <Suspense fallback={<div>Loading video...</div>}>
            <LazyVideo
                src="/ContractVideo.mp4"
                autoPlay
                muted
                loop
                // controls
            />
          </Suspense>
        </VideoContainer>
      </Content>
    </Container>
  );
}

export default Advantages;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 80px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  animation: ${fadeIn} 1.5s ease-out;
`;

const Content = styled.div`
  max-width: 900px;
  text-align: left;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Title = styled.p`
  color: #303030;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.875px;
  line-height: 60px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
  word-break: keep-all;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

// const Video = styled.video`
//   width: 900px;
//   height: 550px;
//   //border: 1px solid black;
//   @media (max-width: 768px) {
//     width: 300px;
//     height: 200px;
//     margin-bottom: 0;
//   }
// `;

const VideoContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
`;

const UnderlineText = styled.span`
  //text-decoration: underline;
  color: #4278f5;
`;

// const BoldText = styled.span`
//     font-weight: 700;
//     `;