/* eslint react/prop-types: 0 */

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function HeroButton({text, width}) {
  const navigate = useNavigate();
  return (
    <Button
        width={width}
      onClick={() => {
        navigate("pages/landing-pages/contact-us");
      }}
    >
        {text}
    </Button>
  );
}

export default HeroButton;
const Button = styled.button`
  background: linear-gradient(90deg, #4278f5 100%, #9342f5 0);
  border-radius: 50px;
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  height: 64px;
  margin-top: 69px;
  transition: all 0.2s;
  width: ${props => props.width || '320px'};
  text-align: center;
  min-height:64px;
  &:hover {
    background: linear-gradient(90deg, #4278f5, #9342f5 105.41%);
    box-shadow: 8px 8px 117px 0.5px #e0dfe5;
  }
`;
