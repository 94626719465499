import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DocumentModal({ open, handleClose, title, content }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
        maxHeight: '80vh',
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="document-modal-title"
            aria-describedby="document-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="document-modal-title" variant="h6" component="h2" sx={{ mb: 2, fontSize: '1.25rem' }}>
                    {title}
                </Typography>
                <Typography id="document-modal-description" sx={{ whiteSpace: 'pre-line', fontSize: '0.875rem' }}>
                    {content}
                </Typography>
            </Box>
        </Modal>
    );
}

DocumentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default DocumentModal;
