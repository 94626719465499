// PaymentInfoModal.js
import React from 'react';
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const PaymentInfoModal = ({ paymentData, onClose }) => {
    if (!paymentData) return null;

    // 오버레이 스타일
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    };

    // 모달창 스타일
    const modalStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        width: '500px',
        height: '220px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative',
    };
    // 닫기 버튼 스타일
    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
    };

    // apprPrice에 콤마 추가
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // apprDt를 년-월-일 시:분:초 형식으로 변환
    const formatDate = (dateString) => {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6, 8);
        const hour = dateString.slice(8, 10);
        const minute = dateString.slice(10, 12);
        return `${year}-${month}-${day} ${hour}:${minute}`;
    };

    // billId의 앞 12자리를 추출하여 결제 발송 일자로 변환
    const formatDate2 = (billId) => {
        const dateString = billId.slice(0, 12);
        const year = dateString.slice(0, 2);
        const month = dateString.slice(2, 4);
        const day = dateString.slice(4, 6);
        const hour = dateString.slice(6, 8);
        const minute = dateString.slice(8, 10);
        return `20${year}-${month}-${day} ${hour}:${minute}`;
    };

    return (
        <div style={overlayStyle} onClick={onClose}>
            <div style={modalStyle} onClick={e => e.stopPropagation()}>
                <div style={{ textAlign: 'right' }}> {/* 오른쪽 정렬을 위한 컨테이너 */}
                    <Button style={closeButtonStyle} onClick={onClose}>닫기</Button>
                </div>
                <h2>결제 내역</h2>
                <br />
                <p>결제 ID: {paymentData.billId}</p>
                <p>결제 금액: {formatPrice(paymentData.apprPrice)}원</p>
                <p>결제 발송 일자: {formatDate2(paymentData.billId)}</p>
                <p>결제 완료 일자: {formatDate(paymentData.apprDt)}</p>
                <p>결제 은행: {paymentData.apprIssuer}</p>
                <p>결제 수단: {paymentData.apprPayType}</p>
            </div>
        </div>
    );
};

PaymentInfoModal.propTypes = {
    paymentData: PropTypes.shape({
        billId: PropTypes.string.isRequired,
        apprPrice: PropTypes.string.isRequired,
        apprDt: PropTypes.number.isRequired,
        apprIssuer: PropTypes.string.isRequired,
        apprPayType: PropTypes.string.isRequired,
        apprCardType: PropTypes.string.isRequired,
        // 필요한 다른 결제 정보 속성 추가
    }),
    onClose: PropTypes.func.isRequired,
};

export default PaymentInfoModal;