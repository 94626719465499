/* eslint react/prop-types: 0 */

import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";

function FAQCard({ question, answer, animate, variants }) {
  const [isOpen, setIsOpen] = useState(false);

  const firstQuestion = question.substring(0,2);
  const restQuestion = question.substring(2);

  return (
    <Container
      animate={animate}
      variants={variants}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      layout
    >
      <Top>
        <Question><Highlight>{firstQuestion}</Highlight>{restQuestion}</Question>
      </Top>
      {/*<Answer animate={{ height: isOpen ? "auto" : 0 }}>{answer}</Answer>*/}
      <Answer animate={{ height: isOpen ? "auto" : 0 }}>{answer}</Answer>
    </Container>
  );
}

export default FAQCard;

const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  cursor: pointer;
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;
const Question = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 32px;
  text-align: left;
  color: #303030;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const Answer = styled(motion.p)`
  color: #808080;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 32px;
  white-space: pre-wrap;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 26px;
  }
  overflow: hidden;
`;

const Highlight = styled.span`
  color: #4278f5; // 원하는 색상 코드
`;