/* eslint react/prop-types: 0 */
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

function Step({ step, title, image, animate, variants, text, onClick}) {
  return (
    <Container animate={animate} variants={variants}>
        <BottomAlignLeftContainer>
            <Bottom>
                <StepCircle>{step}</StepCircle>
                <Text>{title}</Text>
            </Bottom>
        <SubTextContainer>
            <SubText>{text}</SubText>
        </SubTextContainer>
        </BottomAlignLeftContainer>
        <BoxContainer>
            <Box onClick={onClick}>{image}</Box>
        </BoxContainer>
        {step !== '4' && <ClickPrompt>클릭하여 이미지 보기</ClickPrompt>}
    </Container>
  );
}

export default Step;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: -30px;
`;
const Box = styled.div`
  align-items: center;
  background: #f5f5f7;
  border-radius: 500px;
  display: flex;
  height: 300px;
  justify-content: center;
  margin-bottom: 20px;
  width: 210px;
  cursor: pointer;
`;
const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
const Text = styled.p`
  align-items: center;
  color: #303030;
  display: inline-flex;
  font-size: 20px;
  font-weight: 700;
  gap: 10px;
  line-height: 34px;
`;

const SubText = styled.p`
  align-items: center;
  color: #303030;
  display: inline-flex;
  font-size: 18px;
  font-weight: 500;
  gap: 10px;
  line-height: 34px;
  white-space: pre-wrap;
  text-align: left;
  position: relative;
  left: 38px;
`;
const StepCircle = styled.div`
  background: #4971ff;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  width: 28px;
  min-width: 28px;
`;
const SubTextContainer = styled.div`
  width: 100%;
  //padding-left: 5%;
`;

const BoxContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1); /* 호버 시 아이콘 확대 */
    transition: transform 0.3s ease-in-out;
  }
`;

const ClickPrompt = styled.div`
  margin-top: -10px; 
  color: #4971ff;
  font-weight: bold; 
  font-size: 14px; 
  text-align: center; 
`;

const BottomAlignLeftContainer = styled.div`
  align-self: flex-start;
  width: 100%;
  //padding-left: 150px;
  margin-left: 20%;
  @media (max-width: 768px) {
    margin-left: 0%;
  }
`;