import { useEffect } from 'react'; // React를 명시적으로 import합니다.

const ChannelIOComponent = () => {
    useEffect(() => {
        // ChannelIO 스크립트 초기화
        (function() {
            var w = window;
            if (w.ChannelIO) {
                return w.console.error("ChannelIO script included twice.");
            }
            var ch = function() {
                ch.c(arguments);
            };
            ch.q = [];
            ch.c = function(args) {
                ch.q.push(args);
            };
            w.ChannelIO = ch;
            function l() {
                if (w.ChannelIOInitialized) {
                    return;
                }
                w.ChannelIOInitialized = true;
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
                var x = document.getElementsByTagName("script")[0];
                if (x.parentNode) {
                    x.parentNode.insertBefore(s, x);
                }
            }
            if (document.readyState === "complete") {
                l();
            } else {
                w.addEventListener("DOMContentLoaded", l);
                w.addEventListener("load", l);
            }
        })();

        // 이 부분에서 ChannelIO 함수를 호출합니다.
        window.ChannelIO('boot', {
            // "pluginKey": "afca928c-3e22-4494-8bc6-666370ed99d4"

            "pluginKey": "fec18fec-3895-47b5-a372-5d5d8d3263cb"
        });

        // 컴포넌트 언마운트 시 ChannelIO 제거
        return () => {
            if (window.ChannelIO) {
                window.ChannelIO('shutdown');
                delete window.ChannelIO;
            }
            var chScript = document.querySelector('script[src="https://cdn.channel.io/plugin/ch-plugin-web.js"]');
            if (chScript && chScript.parentNode) {
                chScript.parentNode.removeChild(chScript);
            }
            window.ChannelIOInitialized = false;
        };
    }, []);

    return null; // 이 컴포넌트는 UI를 렌더링하지 않습니다.
};

export default ChannelIOComponent;