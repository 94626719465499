// import React from "react";
// import styled from "styled-components";
// import Step from "../components/Step";
// import AssignmentTurnedInIcon from "@mui/icons-material/Assignment"; // 계약 정보 입력 아이콘
// import PaymentIcon from "@mui/icons-material/Payment"; // 결제 아이콘
// import EmailIcon from "@mui/icons-material/Email"; // 이메일 아이콘
// import EditNoteIcon from "@mui/icons-material/EditNote"; // 사업자 등록 아이콘
// import useInViewAnimation from "hooks/useInViewAnimation";
//
// function Procedures() {
//   const getVariant = (index) => ({
//     visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.25 } },
//     hidden: { opacity: 0 },
//   });
//   const [ref, control] = useInViewAnimation(0.2);
//   return (
//     <Container ref={ref}>
//       <Top>
//         <Title>계약 절차</Title>
//         <Text>
//           10초만에 받아보는
//           <br />
//           임대차 계약서
//         </Text>
//       </Top>
//       <Row>
//         <Column>
//           <Step
//             step="1"
//             title="계약 정보 입력"
//             image={
//               <AssignmentTurnedInIcon
//                 style={{ width: "100px", height: "100px", color: "#4971ff" }}
//               />
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(0)}
//           />
//           <Step
//             step="3"
//             title="이메일로 계약서 발송"
//             image={<EmailIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
//             animate={control}
//             initial="hidden"
//             variants={getVariant(2)}
//           />
//         </Column>
//         <Column style={{ marginTop: "240px" }}>
//           <Step
//             step="2"
//             title="결제"
//             image={<PaymentIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
//             animate={control}
//             initial="hidden"
//             variants={getVariant(1)}
//           />
//           <Step
//             step="4"
//             title="사업자등록 진행"
//             image={<EditNoteIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
//             animate={control}
//             initial="hidden"
//             variants={getVariant(3)}
//           />
//         </Column>
//       </Row>
//     </Container>
//   );
// }
//
// export default Procedures;
// const Container = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 150px 100px;
//   background: #fff;
//   gap: 100px;
//   @media (max-width: 1040px) {
//     flex-direction: column;
//     align-items: center;
//     justify-content: flex-start;
//     gap: 30px;
//     padding: 150px 30px;
//     text-align: center;
//   }
// `;
// const Top = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 24px;
//   position: sticky;
//   top: 150px;
//   align-self: flex-start;
//   @media (max-width: 1040px) {
//     width: 100%;
//     position: static;
//     top: 0;
//     text-align: center;
//   }
// `;
// const Title = styled.p`
//   color: #949ca8;
//   font-size: 24px;
//   font-weight: 700;
//   gap: 10px;
//   letter-spacing: -0.88px;
//   line-height: 30px;
//   margin-bottom: 8px;
//   @media (max-width: 768px) {
//     font-size: 20px;
//     line-height: 25px;
//   }
// `;
// const Text = styled.p`
//   color: #303030;
//   font-size: 40px;
//   font-weight: 700;
//   letter-spacing: -0.88px;
//   line-height: 60px;
//   margin-bottom: 24px;
//   @media (max-width: 768px) {
//     font-size: 34px;
//     line-height: 51px;
//   }
// `;
// const Row = styled.div`
//   display: flex;
//   gap: 20px;
//   @media (max-width: 1040px) {
//     scale: 0.8;
//     margin: -100px 0;
//   }
//   @media (max-width: 500px) {
//     scale: 0.55;
//     margin: -300px 0;
//   }
// `;
// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 110px;
// `;

// import React from "react";
// import styled from "styled-components";
// import Step from "../components/Step";
// import useInViewAnimation from "hooks/useInViewAnimation";
//
// import step1Image from "assets/images/contractImg.png";
// import step2Image from "assets/images/pricingImg.jpg";
// import step3Image from "assets/images/emailImg.png";
// import step4Image from "assets/images/contractBlur.png";
// // import PaymentIcon from "@mui/icons-material/Payment"; // 결제 아이콘
// // import EmailIcon from "@mui/icons-material/Email"; // 이메일 아이콘
// // import EditNoteIcon from "@mui/icons-material/EditNote"; // 사업자 등록 아이콘
// function Procedures() {
//   const getVariant = (index) => ({
//     visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.25 } },
//     hidden: { opacity: 0 },
//   });
//   const [ref, control] = useInViewAnimation(0.2);
//   return (
//     <Container ref={ref}>
//       <Top>
//         <Title>계약 절차</Title>
//         <Text>
//           10초만에 받아보는
//           <br />
//           임대차 계약서
//         </Text>
//       </Top>
//       <Row>
//         <Column>
//           <Step
//             step="1"
//             title="계약 정보 입력"
//             image={<a href={step1Image} target="_blank" rel="noopener noreferrer">
//               <img
//                   src={step1Image}
//                   alt="계약 정보 입력"
//                   style={{ width: "200px", height: "250px" }}
//               />
//             </a>}
//             animate={control}
//             initial="hidden"
//             variants={getVariant(0)}
//           />
//           <Step
//             step="3"
//             title="이메일로 계약서 발송"
//             image={<a href={step3Image} target="_blank" rel="noopener noreferrer">
//               <img src={step3Image} alt="이메일로 계약서 발송" style={{ width: "200px", height: "250px" }} />
//             </a>}
//             animate={control}
//             initial="hidden"
//             variants={getVariant(2)}
//           />
//         </Column>
//         <Column style={{ marginTop: "240px" }}>
//           <Step
//             step="2"
//             title="결제"
//             image={<a href={step2Image} target="_blank" rel="noopener noreferrer">
//                 <img src={step2Image} alt="결제" style={{ width: "200px", height: "250px" }} />
//             </a>}
//             animate={control}
//             initial="hidden"
//             variants={getVariant(1)}
//           />
//           <Step
//             step="4"
//             title="사업자등록 진행"
//             image={<a href={step4Image} target="_blank" rel="noopener noreferrer">
//                 <img src={step4Image} alt="사업자등록 진행" style={{ width: "200px", height: "250px" }} />
//             </a>}
//             animate={control}
//             initial="hidden"
//             variants={getVariant(3)}
//           />
//         </Column>
//       </Row>
//     </Container>
//   );
// }
//
// export default Procedures;
// const Container = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 150px 100px;
//   background: #fff;
//   gap: 100px;
//   @media (max-width: 1040px) {
//     flex-direction: column;
//     align-items: center;
//     justify-content: flex-start;
//     gap: 30px;
//     padding: 150px 30px;
//     text-align: center;
//   }
// `;
// const Top = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 24px;
//   position: sticky;
//   top: 150px;
//   align-self: flex-start;
//   @media (max-width: 1040px) {
//     width: 100%;
//     position: static;
//     top: 0;
//     text-align: center;
//   }
// `;
// const Title = styled.p`
//   color: #949ca8;
//   font-size: 24px;
//   font-weight: 700;
//   gap: 10px;
//   letter-spacing: -0.88px;
//   line-height: 30px;
//   margin-bottom: 8px;
//   @media (max-width: 768px) {
//     font-size: 20px;
//     line-height: 25px;
//   }
// `;
// const Text = styled.p`
//   color: #303030;
//   font-size: 40px;
//   font-weight: 700;
//   letter-spacing: -0.88px;
//   line-height: 60px;
//   margin-bottom: 24px;
//   @media (max-width: 768px) {
//     font-size: 34px;
//     line-height: 51px;
//   }
// `;
// const Row = styled.div`
//   display: flex;
//   gap: 20px;
//   @media (max-width: 1040px) {
//     scale: 0.8;
//     margin: -100px 0;
//   }
//   @media (max-width: 500px) {
//     scale: 0.55;
//     margin: -300px 0;
//   }
// `;
// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 110px;
// `;

// import React from "react";
// import styled from "styled-components";
// import Step from "../components/Step";
// import useInViewAnimation from "hooks/useInViewAnimation";
//
// import step1Image from "assets/images/contractImg.png";
// import step2Image from "assets/images/pricingImg.jpg";
// import step3Image from "assets/images/emailImg.png";
// import step4Image from "assets/images/contractBlur.png";
//
//
//
// function Procedures() {
//     const getVariant = (index) => ({
//         visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.25 } },
//         hidden: { opacity: 0 },
//     });
//     const [ref, control] = useInViewAnimation(0.2);
//     // const baseTime = 5; // Starting with 5 seconds for the first step
//
//     return (
//         <Container ref={ref}>
//             <Top>
//                 <Title>계약 절차</Title>
//                 <Text>10초만에 받아보는 임대차 계약서</Text>
//             </Top>
//
//             <Steps>
//                 {[step1Image, step2Image, step3Image, step4Image].map((imageSrc, index) => (
//                     <StepContainer key={index} isOdd={index % 2 !== 0}>
//                         <Step
//                             step={index + 1}
//                             title={["계약 정보 입력", "결제", "이메일로 계약서 발송", "사업자등록 진행"][index]}
//                             image={<a href={imageSrc} target="_blank" rel="noopener noreferrer">
//                                 <img src={imageSrc} alt="Step Image" style={{ width: "250px", height: "350px" }} />
//                             </a>}
//                             animate={control}
//                             initial="hidden"
//                             variants={getVariant(index)}
//                         />
//                     </StepContainer>
//                 ))}
//             </Steps>
//         </Container>
//     );
// }
//
// export default Procedures;
//
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 150px 30px;
//   background: #fff;
// `;
//
// const Top = styled.div`
//   text-align: center;
//   margin-bottom: 75px; // Step 컴포넌트들과의 간격
// `;
//
// const Title = styled.h2`
//   color: #303030;
//   font-size: 40px;
//   font-weight: 700;
//   margin-bottom: 0.5rem;
// `;
//
// const Text = styled.p`
//   color: #949ca8;
//   font-size: 24px;
//   font-weight: 700;
// `;
//
// const Steps = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   margin-bottom: 75px;
//
//   // Step 컴포넌트 사이에 마진 추가
//   & > div:not(:last-child) {
//     margin-bottom: 75px; // 원하는 마진 값으로 조정
//   }
// `;
//
// const StepContainer = styled.div`
//   display: flex;
//   justify-content: ${({ index }) => (index % 2 === 0 ? 'flex-start' : 'flex-end')};
//   width: 100%;
//   padding: 20px;
// `;


// 2024/03/04
// import React from "react";
// import styled from "styled-components";
// import Step from "../components/Step";
// import useInViewAnimation from "hooks/useInViewAnimation";
//
// import step1Image from "assets/images/contractImg.png";
// import step2Image from "assets/images/pricingImg.jpg";
// import step3Image from "assets/images/emailImg.png";
// import step4Image from "assets/images/contractImg2.png";
//
// function Procedures() {
//   const getVariant = (index) => ({
//     visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.25 } },
//     hidden: { opacity: 0 },
//   });
//   const [ref, control] = useInViewAnimation(0.2);
//   return (
//     <Container ref={ref}>
//       <Top>
//         <Text>계약 절차</Text>
//         <Title>10초만에 받아보는 임대차 계약서</Title>
//       </Top>
//       <Steps>
//         <StepOne>
//           <Step
//             step="1"
//             title="계약 정보 입력"
//             image={
//               <a href={step1Image} target="_blank" rel="noopener noreferrer">
//                 <img
//                   src={step1Image}
//                   alt="계약 정보 입력"
//                   style={{ width: "200px", height: "250px" }}
//                 />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(0)}
//           />
//           <Video
//               src="/ContractVideo.mp4"
//               autoPlay
//               muted
//               loop
//               controls
//               />
//
//         </StepOne>
//         <BottomSteps>
//           <Step
//             step="2"
//             title="결제"
//             image={
//               <a href={step2Image} target="_blank" rel="noopener noreferrer">
//                 <img
//                   src={step2Image}
//                   alt="이메일로 계약서 발송"
//                   style={{ width: "200px", height: "250px" }}
//                 />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(1)}
//           />
//           <Step
//             step="3"
//             title="이메일로 계약서 발송"
//             image={
//               <a href={step3Image} target="_blank" rel="noopener noreferrer">
//                 <img src={step3Image} alt="결제" style={{ width: "200px", height: "250px" }} />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(2)}
//           />
//           <Step
//             step="4"
//             title="사업자등록 진행"
//             image={
//               <a href={step4Image} target="_blank" rel="noopener noreferrer">
//                 <img
//                   src={step4Image}
//                   alt="사업자등록 진행"
//                   style={{ width: "200px", height: "250px" }}
//                 />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(3)}
//           />
//         </BottomSteps>
//       </Steps>
//     </Container>
//   );
// }
//
// export default Procedures;
// const Container = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 150px 100px;
//   background: #fff;
//   gap: 100px;
//   flex-direction: column;
//   text-align: center;
// `;
// const Top = styled.div`
//   //width: 100%;
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 24px;
//   align-self: flex-start;
//   width: 100%;
//   position: static;
//   top: 0;
//   text-align: center;
// `;
// const Title = styled.p`
//   color: #949ca8;
//   font-size: 24px;
//   font-weight: 700;
//   gap: 10px;
//   letter-spacing: -0.88px;
//   line-height: 30px;
//   margin-bottom: 8px;
//   @media (max-width: 768px) {
//     font-size: 20px;
//     line-height: 25px;
//   }
// `;
// const Text = styled.p`
//   color: #303030;
//   font-size: 40px;
//   font-weight: 700;
//   letter-spacing: -0.88px;
//   line-height: 60px;
//   @media (max-width: 768px) {
//     font-size: 34px;
//     line-height: 51px;
//   }
// `;
//
// const Steps = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 30px;
// `;
// const StepOne = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 30px;
//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: center;
//     gap: 20px;
//   }
// `;
// const Video = styled.video`
//   width: 450px;
//   height: 250px;
//   border: 1px solid black;
//   margin-bottom: 80px;
//   @media (max-width: 768px) {
//     width: 300px;
//     height: 200px;
//     margin-bottom: 0;
//   }
// `;
// const BottomSteps = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 50px;
//   @media (min-width: 768px) {
//     flex-direction: row;
//     justify-content: center;
//     gap: 20px;
//   }
// `;

// 2024/03/05
// import React from "react";
// import styled from "styled-components";
// import Step from "../components/Step";
// import useInViewAnimation from "hooks/useInViewAnimation";
//
// import step1Image from "assets/images/contractImg.png";
// import step2Image from "assets/images/pricingImg.jpg";
// import step3Image from "assets/images/emailImg.png";
// import step4Image from "assets/images/contractImg2.png";
//
// function Procedures() {
//   const getVariant = (index) => ({
//     visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.25 } },
//     hidden: { opacity: 0 },
//   });
//   const [ref, control] = useInViewAnimation(0.2);
//
//   return (
//     <Container ref={ref}>
//       <Top>
//         <Text>계약 절차</Text>
//         <Title>10초만에 받아보는 임대차 계약서</Title>
//       </Top>
//       <Steps>
//           <Step
//             step="1"
//             title="계약 정보 입력"
//             image={
//               <a href={step1Image} target="_blank" rel="noopener noreferrer">
//                 <img
//                   src={step1Image}
//                   alt="계약 정보 입력"
//                   style={{ width: "300px", height: "400px" }}
//                 />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(0)}
//             text="계약 정보를 입력하세요."
//           />
//           <Step
//             step="2"
//             title="결제"
//             image={
//               <a href={step2Image} target="_blank" rel="noopener noreferrer">
//                 <img
//                   src={step2Image}
//                   alt="결제"
//                   style={{ width: "300px", height: "400px" }}
//                 />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(1)}
//             text="카카오톡에서 결제를 진행하세요."
//           />
//           <Step
//             step="3"
//             title="이메일로 계약서 발송"
//             image={
//               <a href={step3Image} target="_blank" rel="noopener noreferrer">
//                 <img src={step3Image} alt="결제" style={{ width: "300px", height: "400px" }} />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(2)}
//             text="결제가 완료되면 입력하신 이메일로 계약서가 발송됩니다."
//           />
//           <Step
//             step="4"
//             title="사업자등록 진행"
//             image={
//               <a href={step4Image} target="_blank" rel="noopener noreferrer">
//                 <img
//                   src={step4Image}
//                   alt="사업자등록 진행"
//                   style={{ width: "300px", height: "400px" }}
//                 />
//               </a>
//             }
//             animate={control}
//             initial="hidden"
//             variants={getVariant(3)}
//             text="계약서를 받은 후 사업자등록을 진행하세요."
//           />
//       </Steps>
//     </Container>
//   );
// }
//
// export default Procedures;
// const Container = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 150px 100px;
//   background: #fff;
//   gap: 100px;
//   flex-direction: column;
//   text-align: center;
// `;
// const Top = styled.div`
//   //width: 100%;
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 24px;
//   align-self: flex-start;
//   width: 100%;
//   position: static;
//   top: 0;
//   text-align: center;
// `;
// const Title = styled.p`
//   color: #949ca8;
//   font-size: 24px;
//   font-weight: 700;
//   gap: 10px;
//   letter-spacing: -0.88px;
//   line-height: 30px;
//   margin-bottom: -30px;
//   @media (max-width: 768px) {
//     font-size: 20px;
//     line-height: 25px;
//   }
// `;
// const Text = styled.p`
//   color: #303030;
//   font-size: 40px;
//   font-weight: 700;
//   letter-spacing: -0.88px;
//   line-height: 60px;
//   @media (max-width: 768px) {
//     font-size: 34px;
//     line-height: 51px;
//   }
// `;
//
// const Steps = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 100px;
// `;

// 24/03/11 - 화살표 추가
/* eslint react/prop-types: 0 */

// import React from "react";
// import styled from "styled-components";
// import Step from "../components/Step";
// import AssignmentTurnedInIcon from "@mui/icons-material/Assignment"; // 계약 정보 입력 아이콘
// import PaymentIcon from "@mui/icons-material/Payment"; // 결제 아이콘
// import EmailIcon from "@mui/icons-material/Email"; // 이메일 아이콘
// import EditNoteIcon from "@mui/icons-material/EditNote"; // 사업자 등록 아이콘
// import useInViewAnimation from "hooks/useInViewAnimation";
//
// import step1Image from "assets/images/contractImg.JPG";
// import step2Image from "assets/images/pricingImg.png";
// import step3Image from "assets/images/emailImg.png";
// // import step4Image from "assets/images/contractImg2.png";
//
//
// function Procedures() {
//     const getVariant = (index) => ({
//         visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.25 } },
//         hidden: { opacity: 0 },
//     });
//     const openImageInNewWindow = (imageUrl) => {
//         window.open(imageUrl, "_blank");
//     };
//     const [ref, control] = useInViewAnimation(0.2);
//     return (
//         <Container ref={ref}>
//                 <Title>계약 절차</Title>
//                 {/*<Text>*/}
//                 {/*    10초만에 받아보는*/}
//                 {/*    <br />*/}
//                 {/*    임대차 계약서*/}
//                 {/*</Text>*/}
//             <Row>
//                 <Column>
//                     <Step
//                         step="1"
//                         title="계약정보 입력하기"
//                         image={
//                             <AssignmentTurnedInIcon
//                                 style={{ width: "100px", height: "100px", color: "#4971ff" }}
//                             />
//                         }
//                         onClick={() => openImageInNewWindow(step1Image)}
//                         animate={control}
//                         initial="hidden"
//                         variants={getVariant(0)}
//                         text={`계약 페이지 왼쪽에 계약 정보를 입력하면,\n오른쪽에 계약서가 실시간으로 작성됩니다.\n사인까지 완료하신 후 제출하기를 누르시면 결제알림톡이 전송됩니다.`}
//                     />
//                     <ArrowContainer>
//                         <Arrow direction="right" />
//                     </ArrowContainer>
//                     <Step
//                         step="3"
//                         title="이메일로 계약서 발송"
//                         image={<EmailIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
//                         animate={control}
//                         initial="hidden"
//                         variants={getVariant(2)}
//                         text={`받으신 이메일 첨부파일에 있는 계약서를 가지고\n사업자등록을 진행하시면 됩니다.`}
//                         onClick={() => openImageInNewWindow(step3Image)}
//                     />
//                     <ArrowContainer>
//                         <Arrow direction="right" />
//                     </ArrowContainer>
//                 </Column>
//                 <Column style={{ marginTop: "240px" }}>
//                     <Step
//                         step="2"
//                         title="결제"
//                         image={<PaymentIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
//                         animate={control}
//                         initial="hidden"
//                         variants={getVariant(1)}
//                         text={`제출하기 버튼을 누르시면 자동으로 \n카카오톡채널 '결제선생'에서 결제 청구서를 발송합니다.\n'납부하러 가기' 버튼을 눌러 납부해주시면 \n입력하신 이메일로 계약서가 발송됩니다.`}
//                         onClick={() => openImageInNewWindow(step2Image)}
//                     />
//                     <ArrowContainer2>
//                         <Arrow direction="left" />
//                     </ArrowContainer2>
//                     <Step
//                         step="4"
//                         title="사업자등록 진행"
//                         image={<EditNoteIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
//                         animate={control}
//                         initial="hidden"
//                         variants={getVariant(3)}
//                         // text={`완료`}
//                         // onClick={() => openImageInNewWindow(step4Image)}
//                     />
//                 </Column>
//             </Row>
//         </Container>
//     );
// }
//
// export default Procedures;
// const Container = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   padding: 150px 100px;
//   background: #fff;
//   gap: 100px;
// `;
// const Title = styled.p`
//   color: #303030;
//   font-size: 50px;
//   font-weight: 700;
//   gap: 10px;
//   letter-spacing: -0.88px;
//   line-height: 30px;
//   //margin-bottom: 0px;
//   text-align: center;
//   @media (max-width: 768px) {
//     font-size: 34px;
//     line-height: 30px;
//   }
// `;
// const Row = styled.div`
//   display: flex;
//   gap: 20px;
//   @media (max-width: 1040px) {
//     scale: 0.8;
//     margin: -100px 0;
//   }
//   @media (max-width: 500px) {
//     scale: 0.55;
//     margin: -300px 0;
//   }
// `;
// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 110px;
// `;
//
// const Arrow = styled.div`
//   //position: relative;
//   width: 0;
//   height: 0;
//   border-top: 20px solid transparent;
//   border-bottom: 20px solid transparent;
//   border-left: 20px solid #4278f5; // 화살표 색상 설정
//   //margin: 20px 0; // 화살표 간격 설정
//   transform: ${props => props.direction === "right" ? 'rotate(30deg)' : 'rotate(150deg)'}; // 방향에 따른 회전
//   text-align: right;
//   margin-top: -150px;
//
//   &::after {
//     content: '';
//     position: absolute;
//     right: 15px; /* 꼬리의 위치 조정 */
//     top: -10px; /* 꼬리의 높이를 화살표 높이와 맞추기 위한 조정 */
//     width: 0;
//     height: 0;
//     border-top: 10px solid transparent;
//     border-bottom: 10px solid transparent;
//     border-right: 40px solid #4278f5; /* 꼬리의 색상과 화살표 본체 색상을 맞춤 */
//   }
// `;
//
// const ArrowContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
// `;
//
// const ArrowContainer2 = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   //margin-left: -20px;
//   width: 100%;
// `;

/* eslint react/prop-types: 0 */

import React from "react";
import styled from "styled-components";
import Step from "../components/Step";
import AssignmentTurnedInIcon from "@mui/icons-material/Assignment"; // 계약 정보 입력 아이콘
import PaymentIcon from "@mui/icons-material/Payment"; // 결제 아이콘
import EmailIcon from "@mui/icons-material/Email"; // 이메일 아이콘
import EditNoteIcon from "@mui/icons-material/EditNote"; // 사업자 등록 아이콘
import useInViewAnimation from "hooks/useInViewAnimation";

import step1Image from "assets/images/contractImg.JPG";
import step2Image from "assets/images/pricingImg.png";
import step3Image from "assets/images/emailImg.png";
// import step4Image from "assets/images/contractImg2.png";


function Procedures() {
    const getVariant = (index) => ({
        visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.25 } },
        hidden: { opacity: 0 },
    });
    const openImageInNewWindow = (imageUrl) => {
        window.open(imageUrl, "_blank");
    };
    const [ref, control] = useInViewAnimation(0.2);
    return (
        <Container ref={ref}>
                <Title>계약 절차</Title>
                {/*<Text>*/}
                {/*    10초만에 받아보는*/}
                {/*    <br />*/}
                {/*    임대차 계약서*/}
                {/*</Text>*/}
            <Row>
                <Column>
                    <Step
                        step="1"
                        title="계약정보 입력하기"
                        image={
                            <AssignmentTurnedInIcon
                                style={{ width: "100px", height: "100px", color: "#4971ff" }}
                            />
                        }
                        onClick={() => openImageInNewWindow(step1Image)}
                        animate={control}
                        initial="hidden"
                        variants={getVariant(0)}
                        text={`계약 페이지 왼쪽에 계약 정보를 입력하면,\n오른쪽에 계약서가 실시간으로 작성됩니다.\n사인까지 완료하신 후 제출하기를 누르시면 결제알림톡이 전송됩니다.`}
                    />
                    <Step
                        step="3"
                        title="이메일로 계약서 발송"
                        image={<EmailIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
                        animate={control}
                        initial="hidden"
                        variants={getVariant(2)}
                        text={`받으신 이메일 첨부파일에 있는 계약서를 가지고\n사업자등록을 진행하시면 됩니다.`}
                        onClick={() => openImageInNewWindow(step3Image)}
                    />
                </Column>
                <ArrowColumn>
                    <Arrow direction="right" />
                    <Arrow direction="left" />
                    <Arrow direction="right" />
                </ArrowColumn>
                <Column style={{ marginTop: "240px" }}>
                    <Step
                        step="2"
                        title="결제"
                        image={<PaymentIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
                        animate={control}
                        initial="hidden"
                        variants={getVariant(1)}
                        text={`제출하기 버튼을 누르시면 자동으로 \n카카오톡채널 '결제선생'에서 결제 청구서를 발송합니다.\n'납부하러 가기' 버튼을 눌러 납부해주시면 \n입력하신 이메일로 계약서가 발송됩니다.`}
                        onClick={() => openImageInNewWindow(step2Image)}
                    />
                    <Step
                        step="4"
                        title="사업자등록 진행"
                        image={<EditNoteIcon style={{ width: "100px", height: "100px", color: "#4971ff" }} />}
                        animate={control}
                        initial="hidden"
                        variants={getVariant(3)}
                        // text={`완료`}
                        // onClick={() => openImageInNewWindow(step4Image)}
                    />
                </Column>
            </Row>
        </Container>
    );
}

export default Procedures;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 150px 100px;
  background: #fff;
  gap: 100px;
`;
const Title = styled.p`
  color: #303030;
  font-size: 50px;
  font-weight: 700;
  gap: 10px;
  letter-spacing: -0.88px;
  line-height: 30px;
  //margin-bottom: 0px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 30px;
  }
`;
const Row = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1040px) {
    scale: 0.8;
    margin: -100px 0;
  }
  @media (max-width: 500px) {
    scale: 0.55;
    margin: -300px 0;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 110px;
`;

const ArrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; // 화살표를 중앙에 위치시킴
  align-items: center;
  gap: 20px; // 각 화살표 사이의 간격 조정
`;

const Arrow = styled.div`
  position: relative;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #4278f5; // 화살표 색상 설정
  //margin: 20px 0; // 화살표 간격 설정
  transform: ${props => props.direction === "right" ? 'rotate(30deg)' : 'rotate(150deg)'}; // 방향에 따른 회전
  text-align: right;
  margin-top: 220px;

  &::after {
    content: '';
    position: absolute;
    right: 15px; /* 꼬리의 위치 조정 */
    top: -10px; /* 꼬리의 높이를 화살표 높이와 맞추기 위한 조정 */
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 40px solid #4278f5; /* 꼬리의 색상과 화살표 본체 색상을 맞춤 */
  }

  @media (max-width: 768px) {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #4278f5; // 화살표 색상
    transform: ${(props) => props.direction === "right" ? 'rotate(30deg)' : 'rotate(150deg)'}; // 방향에 따른 회전 조정
    &::after {
      right: 11px; // 화면이 작을 때 꼬리 위치
      top: -7px; // 화면이 작을 때 꼬리 높이 조정
      border-top: 7.5px solid transparent;
      border-bottom: 7.5px solid transparent;
      border-right: 30px solid #4278f5; // 화면이 작을 때 꼬리 색상
    }
  }
`;