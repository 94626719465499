import React from "react";
import styled from "styled-components";
import useInViewAnimation from "hooks/useInViewAnimation";
import FAQCard from "../components/FAQCard";

function FAQ() {
  const getVariant = (index) => ({
    visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.07 + 0.4 } },
    hidden: { opacity: 0 },
  });
  const [ref, control] = useInViewAnimation(0.0);
  const data = [
    {
      question: "Q1. 사무실 주소로 우편물이나 택배가 올 땐 어떻게 해주시나요?",
      answer:
        "   A. 월 1회 우편물 사진촬영하여 보내드리는 서비스를 하고 있습니다. \n" +
          "        우편물 등은 2주간 보관 후 폐기 처리하고 있습니다. \n"
    },
    {
      question: "Q2. 법인 사업자도 계약이 가능한가요?",
      answer:
        "   A. 아쉽게도 현재까진 개인 사업자분들만 계약을 도와드리고 있습니다.",
    },
    {
      question: "Q3. 건강기능식품이나 화장품 판매, 수입식품 인터넷 구매대행업 사업자 등록이 가능한가요?",
      answer:
          "   A. 현재 해당 업종으로는 사업자 등록이 어렵습니다."
          //+ "        추후 해당 업종까지 사업자등록하실 수 있도록 확대할 예정입니다."
    },
    {
      question: "Q4. 사업자등록 신청이 반려되면 어떡하죠?",
      answer:
        "   A. 만일 결제를 했는데 사업자 등록이 반려될 경우 100% 전액 환불을 해드리고 있으니 \n" +
          "        안심하고 진행해 주셔도 됩니다.\n" +
          "        다만 인허가가 필요한 업종 및 상주 공간이 반드시 있어야 하는 일부 업종은 등록이 제한될 수 있습니다.\n" +
          "        사업자 등록 완료 후 건강기능식품, 화장품, 수입식품 인터넷 구매대행업으로 추가 영업신고하여 \n" +
          "        그 등록이 반려되어도 이로 인한 환불은 불가함을 알려드립니다."
    },
    {
      question: "Q5. 계약 기간이 만료되면 어떻게 해야 하나요?",
      answer:
        "   A. 계약 기간 만료되는 월 1일에 담당 매니저가 계약서에 작성하신 연락처로 연락드립니다.  \n" +
          "        이때 계약 연장 또는 계약 해지 의사를 밝혀주시면 됩니다. \n" +
          "        계약 기간 종료 후 폐업이나 주소 이전을 하지 않으면 임대차 계약이 지속되는 것으로 간주하며, \n" +
          "        3기 이상의 차임 연체 시 세무서에 사업자 등록 직권 말소 신청을 하게 됩니다."
    },
    {
      question: "Q6. 다수의 사업자 등록이 가능한가요?",
      answer:
        "   A. 다수의 사업자 등록은 우측 하단의 상담 버튼을 눌러서 채팅으로 문의 남겨주세요. \n"
    },
    {
      question: "Q7. 추가로 궁금한 사항이 있어요.",
      answer:
          "   A. 우측 하단의 상담 버튼을 눌러서 채팅으로 문의 남겨주세요 :)\n"
    },
  ];
  return (
    <Container id="faqSection" ref={ref}>
      <Contents>
        <Top>
          <Title>자주 묻는 질문</Title>
        </Top>
        <Cards>
          {data.map((el, idx) => (
            <FAQCard
              question={el.question}
              answer={el.answer}
              key={idx}
              animate={control}
              initial="hidden"
              variants={getVariant(idx)}
            />
          ))}
        </Cards>
      </Contents>
    </Container>
  );
}

export default FAQ;
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background: #f5f5f7;
`;
const Contents = styled.div`
  width: 53%;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: center;
`;
const Title = styled.p`
  color: #303030;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.88px;
  line-height: 60px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 51px;
  }
`;
const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
