import React, { useState, useEffect } from "react";
import MKInput from "../MKInput";
import MKButton from "../MKButton";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

const AddressAPI = ({ onAddressSelect }) => {
  // 주소 관련 상태 정의
  const [postcode, setPostcode] = useState("");
  const [roadAddress, setRoadAddress] = useState("");
  // const [jibunAddress, setJibunAddress] = useState('');
  const [extraAddress, setExtraAddress] = useState("");
  const [guide, setGuide] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  // 상세주소 입력 핸들러
  const handleDetailAddressChange = (e) => {
    setDetailAddress(e.target.value);

    // 상세 주소 입력 시 전체 주소 업데이트
    const fullAddress = `${roadAddress} ${e.target.value}`;
    onAddressSelect(fullAddress);
  };
  // 주소 검색 완료 시 호출되는 함수
  const handleComplete = (data) => {
    let extraRoadAddr = "";

    // 법정동명 및 건물명 추가
    if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
      extraRoadAddr += data.bname;
    }
    if (data.buildingName !== "" && data.apartment === "Y") {
      extraRoadAddr += extraRoadAddr !== "" ? ", " + data.buildingName : data.buildingName;
    }
    if (extraRoadAddr !== "") {
      extraRoadAddr = " (" + extraRoadAddr + ")";
    }

    // 상태 업데이트
    setPostcode(data.zonecode);
    // 참고항목 지우기 전
    // setRoadAddress(data.roadAddress);
    // // setJibunAddress(data.jibunAddress);
    // setExtraAddress(data.roadAddress !== "" ? extraRoadAddr : "");


    const updatedRoadAddress = data.roadAddress + extraRoadAddr;
    // 참고항목 지우기 후
    setRoadAddress(data.roadAddress + extraRoadAddr);
    setExtraAddress(extraRoadAddr);

    // const fullAddress = `${roadAddress} ${detailAddress}`;
    //
    // onAddressSelect(fullAddress);
    onAddressSelect(`${updatedRoadAddress} ${detailAddress}`);

    // 사용자가 선택 안함을 클릭한 경우 가이드 표시
    if (data.autoRoadAddress) {
      const expRoadAddr = data.autoRoadAddress + extraRoadAddr;
      setGuide("(예상 도로명 주소 : " + expRoadAddr + ")");
    } else if (data.autoJibunAddress) {
      const expJibunAddr = data.autoJibunAddress;
      setGuide("(예상 지번 주소 : " + expJibunAddr + ")");
    } else {
      setGuide("");
    }
  };

  // 컴포넌트가 마운트될 때 한 번만 실행되는 useEffect
  useEffect(() => {
    // 스크립트 태그 생성 및 추가
    const script = document.createElement("script");
    script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
    script.onload = () => {
      window.daum.postcode.load(() => {});
    };
    document.head.appendChild(script);
  }, []);

  // 상태가 변경될 때마다 실행되는 useEffect
  useEffect(() => {
    if (postcode && roadAddress && detailAddress) {
      const fullAddress = `${roadAddress} ${detailAddress}`;
      onAddressSelect(fullAddress);
    }
  }, [postcode, roadAddress, extraAddress, detailAddress]); // 의존성 배열에 상태 추가

  // 우편번호 검색 실행 함수
  const execDaumPostcode = () => {
    new window.daum.Postcode({
      oncomplete: handleComplete,
    }).open();
  };

  return (
    <div>
      <Grid container spacing={2}>
        {/* 각 필드에 상태 바인딩 */}
        <Grid item xs={12}>
          <MKInput
            type="text"
            value={postcode}
            readOnly
            label="우편번호"
            className="red-asterisk"
            InputLabelProps={{ shrink: true, style:
                  { color: "black",
                    fontFamily: "Pretendard",
                    fontSize: "18px",
                    fontWeight: 700,},}}
            required
            style={{ width: "50%", marginRight: "3%" }}
          />
          <MKButton type="button" onClick={execDaumPostcode}>
            우편번호 찾기*
          </MKButton>
          <br />
        </Grid>
        <Grid item xs={12}>
          <MKInput
            type="text"
            value={roadAddress}
            readOnly
            label="도로명주소"
            className="red-asterisk"
            InputLabelProps={{ shrink: true, style:
                  { color: "black",
                    fontFamily: "Pretendard",
                    fontSize: "18px",
                    fontWeight: 700,},}}
            style={{ width: "100%" }}
            required
          />
          {/*<input type="text" value={jibunAddress} readOnly placeholder="지번주소" />*/}
          {/*<MKInput*/}
          {/*  type="text"*/}
          {/*  value={extraAddress}*/}
          {/*  readOnly*/}
          {/*  label="참고항목"*/}
          {/*  InputLabelProps={{ shrink: true }}*/}
          {/*  style={{ width: "27%", marginLeft: "3%" }}*/}
          {/*/>*/}
        </Grid>
        <span style={{ color: "#999", fontSize: "12px" }}>{guide}</span>
        <Grid item xs={12}>
          <MKInput
            type="text"
            value={detailAddress}
            onChange={handleDetailAddressChange}
            label="상세주소"
            InputLabelProps={{ shrink: true, style:
                  { color: "black",
                    fontFamily: "Pretendard",
                    fontSize: "18px",
                    fontWeight: 700,},}}
            // onChange={(e) => setDetailAddress(e.target.value)}
            style={{ width: "60%" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

AddressAPI.propTypes = {
  onAddressSelect: PropTypes.func.isRequired,
};

export default AddressAPI;
