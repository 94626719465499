import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
// import { Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import axios from "axios";

import Swal from "sweetalert2";
// import {confirmAlert} from 'react-confirm-alert';
import '../DashBoard/custom-react-confirm-alert.css';

import bgImage from "assets/images/6.png";
import DatePick from "../../../components/DatePick";
import { Radio, FormControlLabel, RadioGroup } from "@mui/material";
import AddressAPI from "../../../components/AddressAPI";
import Signature from "../../../components/Signature";
import AgreementForm from "./AgreementForm";
import styled from "styled-components";
// import PDFViewer from "./PDFViewer";
import ImageTextOverlay from "./ImageTextOverlay";
import {showSuccessToast, showErrorToast} from "./toastHelper";

function ContactUs() {
  const [memberName, setMemberName] = useState("");
  const [memberPhoneNumber, setMemberPhoneNumber] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [memberAddress, setMemberAddress] = useState("");
  const [memberIDNumber, setMemberIDNumber] = useState(""); // 사업자 등록번호 [선택 사항]
  // const [memberBusinessNumber, setMemberBusinessNumber] = useState(""); // 사업자 등록번호 [선택 사항]

  const [phoneError, setPhoneError] = useState(false); // 전화번호 형식 에러 상태
  const [emailError, setEmailError] = useState(false); // 이메일 형식 에러 상태
  const [idNumberError, setIdNumberError] = useState(false); // 사업자 등록번호 형식 에러 상태
  // const [businessNumberError, setBusinessNumberError] = useState(false); // 사업자 등록번호 형식 에러 상태

  const [contractStartDate, setContractStartDate] = useState(new Date());
  const [contractDuration, setContractDuration] = useState(24);
  const [contractPrice, setContractPrice] = useState(237600);
  const [contractStatus, setContractStatus] = useState("결제 전");

  // const navigate = useNavigate();
  const location = useLocation();
  // const pdfFile = "/TenSeconds 임대차 계약서.pdf";

  const [signature, setSignature] = useState(null);

  // 개인정보 동의 상태 관리
  const [isAgreed, setIsAgreed] = useState(false);
  // 계약 내용 저장 관리
  const [savedData, setSavedData] = useState(null);

  const [formKey, setFormKey] =useState(0);

  // 체크박스 변경 핸들러
  const handleAgreementChange = (agreed) => {
    setIsAgreed(agreed);
  };

  const [isSubmitting, setIsSubmitting] = useState(false); // 폼 제출 상태

  useEffect(() => {
    // console.log("Initial contractStartDate:", contractStartDate);
    // console.log("Initial contractStartDate (UTC):", contractStartDate.toUTCString());
    // console.log("Initial contractStartDate (Local):", contractStartDate.toString());
    if (location.state && location.state.contractStatus) {
      setContractStatus(location.state.contractStatus);
    }
  }, [location]);

  useEffect(() => {}, [contractStatus]);

  useEffect(() => {
    Swal.fire({
      title: '중요 안내',
      html: '<b>1) 건강기능식품 <br>2) 화장품 판매 <br>3) 수입식품 인터넷 구매대행업 <br>4) 법인 사업자 <br></b> 사업자 등록은 불가함을 꼭 확인해주세요!',
      icon: 'warning',
      confirmButtonText: '확인'
    });
  }, []);

  // 드롭다운 선택 핸들러
  // const handleBusinessTypeChange = (event) => {
  //   setContractBusinessType(event.target.value); // 드롭다운에서 선택한 값을 업데이트
  // };

  const handleNameChange = (event) => {
    setMemberName(event.target.value);
  };

  // 날짜 선택 핸들러
  const handleDateChange = (date) => {
    setContractStartDate(date); // 선택한 시작 날짜
  };

  const handleContractDurationChange = (event) => {
    // event.target.value는 문자열
    const duration = event.target.value;
    setContractDuration(duration);

    let price = 0;

    if (contractStatus !== "무상") {
      if(duration === "6"){
        price = 198000;
      } else if (duration === "12") {
        price = 264000;
      } else if (duration === "24") {
        price = 237600;
      }
    }
    setContractPrice(price);
  };

  // 전화번호 형식 검사
  const checkPhoneFormat = (phone) => {
    const phoneRegex = /^\d{10,11}$/;
    // const phoneRegex = /^(02|0\d{2})-\d{3,4}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  // 이메일 형식 검사
  const checkEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // 사업자 등록 번호 형식 검사
  // const checkBusinessNumberFormat = (businessNumber) => {
  //   const businessNumberRegex = /^\d{3}-\d{2}-\d{5}$/;
  //   return businessNumberRegex.test(businessNumber);
  // };

  // 주민등록번호 형식 검사
  const checkIDNumberFormat = (idNumber) => {
    const idNumberRegex = /^\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])-[1-4]\d{6}$/;
    return idNumberRegex.test(idNumber);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value.replace(/-/g, ""); // '-' 제거 후 검사
    setMemberPhoneNumber(phone);

    if (phone.length > 0 && !checkPhoneFormat(phone)) {
      setPhoneError(true); // 형식이 틀리면 에러 상태를 true로 설정
    } else {
      setPhoneError(false); // 형식이 맞으면 에러 상태를 false로 설정
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setMemberEmail(email);

    if (email.length > 0 && !checkEmailFormat(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleIDNumberChange = (e) => {
    const idNumber = e.target.value;
    setMemberIDNumber(idNumber);

    if (idNumber.length > 0 && !checkIDNumberFormat(idNumber)) {
      setIdNumberError(true);
    } else {
      setIdNumberError(false);
    }
  };

  const handleAddressSelect = (fullAddress) => {
    setMemberAddress(fullAddress);
  };

  const handleSignatureSave = (signature) => {
    setSignature(signature);
  };

  // 작성한 내용 불러오기 버튼 추가
  // const handleLoadSavedData = () => {
  //   confirmAlert({
  //     title: '작성한 내용 불러오기',
  //     message: <>이전에 작성하신 내용을 <br/>불러오시겠습니까?<br/> <span style={{ fontSize: "0.9em" }}>
  //     [이름, 전화번호, 이메일, 주민등록번호, 주소]
  //   </span></>,
  //     buttons: [
  //       {
  //         label: '네',
  //         onClick: () => {
  //           if (savedData) {
  //             setMemberName(savedData.memberDto.memberName);
  //             setMemberPhoneNumber(savedData.memberDto.memberPhoneNumber);
  //             setMemberEmail(savedData.memberDto.memberEmail);
  //             setMemberAddress(savedData.memberDto.memberAddress);
  //             setMemberIDNumber(savedData.memberDto.memberIDNumber);
  //             setContractStartDate(new Date(savedData.contractDto.contractStartDate));
  //             setContractDuration(savedData.contractDto.contractDuration);
  //             setContractPrice(savedData.contractDto.contractPrice);
  //           } else {
  //             showInfoToast('불러올 내용이 없습니다.');
  //           }
  //         },
  //       },
  //       {
  //         label: '아니오',
  //         onClick: () => {},
  //       },
  //     ],
  //   });
  // };

  const handleLoadSavedData = () => {
    Swal.fire({
      title: '작성한 내용 불러오기',
      html: `이전에 작성하신 내용을 불러오시겠습니까?<br/><br/> <span style="font-size: 0.9em">
      [이름, 전화번호, 이메일, 주민등록번호, 주소]
    </span>`, // HTML 태그 사용을 위해 'text' 대신 'html' 속성 사용
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '네',
      cancelButtonText: '아니오'
    }).then((result) => {
      if (result.isConfirmed) {
        if (savedData) {
          setMemberName(savedData.memberDto.memberName);
          setMemberPhoneNumber(savedData.memberDto.memberPhoneNumber);
          setMemberEmail(savedData.memberDto.memberEmail);
          setMemberAddress(savedData.memberDto.memberAddress);
          setMemberIDNumber(savedData.memberDto.memberIDNumber);
          setContractStartDate(new Date(savedData.contractDto.contractStartDate));
          setContractDuration(savedData.contractDto.contractDuration);
          setContractPrice(savedData.contractDto.contractPrice);
        } else {
          Swal.fire('불러올 내용이 없습니다.', '', 'info');
        }
      }
    });
  };

  const AskHandler = async (event) => {
    event.preventDefault();

    const result = await Swal.fire({
      title: '제출 확인',
      text: "제출 후에는 작성하신 계약에 대한 수정이 어렵습니다.\n 제출하시겠습니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '네, 제출합니다',
      cancelButtonText: '아니오, 취소합니다'
    });

    setIsSubmitting(true); // 폼 제출 상태를 true로 설정

    if(result.isConfirmed) {
      setIsSubmitting(true);
    // 필수 입력 필드가 비어 있는지 확인
    if (!memberName || !memberPhoneNumber || !memberEmail || !memberAddress || !signature) {
      showErrorToast("모든 필수 입력 필드를 작성하고 서명을 완료해주세요.");
      setIsSubmitting(false); // 폼 제출 상태를 false로 설정
      return; // 함수 종료
    }

    // 전화번호, 이메일, 주민등록번호 형식 검사
    const isPhoneValid = checkPhoneFormat(memberPhoneNumber);
    const isEmailValid = checkEmailFormat(memberEmail);
    // const isBusinessNumberValid =
    const isIDNumberValid = checkIDNumberFormat(memberIDNumber);
    //     memberBusinessNumber.length === 0 || checkBusinessNumberFormat(memberBusinessNumber);

    // if (!isPhoneValid || !isEmailValid || !isBusinessNumberValid) {
    if (!isPhoneValid || !isEmailValid || !isIDNumberValid) {
      setIsSubmitting(false); // 폼 제출 상태를 false로 설정
      showErrorToast("입력한 정보 중 올바르지 않은 정보가 있습니다. 다시 확인해주세요.");
      return;
    }

    if (!isAgreed) {
      setIsSubmitting(false); // 폼 제출 상태를 false로 설정
      showErrorToast("개인정보 수집 및 이용, 서비스 이용약관에 동의해주세요.");
      return;
    }
    // 현재 날짜에 1일 추가 결제 파기일
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    // 날짜를 YYYY-MM-DD 형식으로 변환
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const formattedExpireDate = `${year}-${month}-${day}`;

    // 필수 입력 필드 중 하나라도 비어있는지 확인
    if (!memberName || !memberPhoneNumber || !memberEmail || !memberAddress) {
      alert("모든 필수 입력 필드를 작성해주세요.");
      return; // 필수 입력 필드가 비어있으면 함수 종료
    }
    if (!checkEmailFormat(memberEmail)) {
      alert("올바른 이메일 주소를 입력하세요.");
      return;
    }
    if (!contractStartDate) {
      alert("날짜를 선택해주세요");
      return;
    }

  const data = {
    memberDto: {
      memberName,
      memberPhoneNumber,
      memberEmail,
      memberAddress,
      memberIDNumber,
      // memberBusinessNumber,
    },
    contractDto: {
      contractStartDate,
      // contractEndDate,
      contractDuration,
      contractPrice,
      // contractBusinessType,
      // contractComment,
      contractStatus,
    },
    paymentDto: {
      product_nm: "TenSeconds " + contractDuration + "개월 이용료",
      member_nm: memberName,
      phone: memberPhoneNumber,
      price: contractPrice,
      expire_dt: formattedExpireDate,
    },
    signature,
  };

      // console.log("Data sent to server:", data);
  axios
      .post("/pages/landing-pages/contact-us", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const count = response.data;

        // 전환 추적 이벤트 실행
        gtag('event', 'conversion', {
          'send_to': 'AW-16527223080/YBjLCM3HuKUZEKjS5cg9',
          'value': contractPrice,
          'currency': 'KRW'
        });

        showSuccessToast(
            <div>
              계약 정보 입력이 정상적으로 완료되었습니다.
              <br />
              입력한 전화번호로 결제 알림톡이 발송됩니다.
              <br />
              [해당 명의로 {count + 1}번 계약 진행하였습니다.]
            </div>,
            {
              autoClose: false,
              closeButton: true,
              closeButtonStyle: {
                color: "#fff",
              },
            }
        );
        setSavedData(data);
        // navigate("/");
      })
      .catch(error=> {
        if(error.response){
          const errorMessages = error.response.data;
          const statusCode = error.response.status;

          if(statusCode === 400 && errorMessages === "Error: User already exists."){
            showErrorToast(
                <div>
                  한 명의당 계약 건수를 초과하였습니다. <br /> 자세한 내용은 문의를 통해 확인해주세요.
                </div>
            );
        }
          else{
            showErrorToast(
                <div>
                  계약 정보 입력에 실패했습니다. <br /> 다시 시도해주세요.
                </div>
            );}}
        // navigate("/");
      })
      .finally(() => {
        setIsSubmitting(false); // 폼 제출 상태를 false로 설정

        setMemberName("");
        setMemberPhoneNumber("");
        setMemberEmail("");
        setMemberAddress("");
        setMemberIDNumber("");
        setContractStartDate(new Date());
        setContractDuration(24);
        setContractPrice(237600);
        setSignature(null);
        setIsAgreed(false);

        setFormKey((prevKey) => prevKey + 1);
      });
}else{
  setIsSubmitting(false);
}
  };

  return (
      <Bg>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <StyledMKBox
                bgColor="white"
                borderRadius="xl"
                shadow="lg"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={{ xs: 20, sm: 18, md: 20 }}
                mb={{ xs: 20, sm: 18, md: 20 }}
            >
              <MKBox
                  variant="gradient"
                  bgColor="info"
                  coloredShadow="info"
                  borderRadius="lg"
                  p={2}
                  mx={2}
                  mt={-3}
              >
                <MKTypography variant="h3" color="white">
                  {contractStatus === "무상" ? "무상 계약 정보 입력" : "계약 정보 입력"}
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="black" fontWeight="bold" mb={3}>
                  1. 제출하기를 누르시면 해당 정보로 결제 알림톡을 발송합니다.
                    <br /> 2. 입력하신 내용은 실시간으로 우측 계약서에 반영되며, 직접 작성하신 계약내용 그대로 계약이 진행됩니다.
                    <br /> 3. 문의사항 있으시면 우측하단의 채팅상담하기를 눌러주세요
                </MKTypography>
                <MKBox
                    width="100%"
                    component="form"
                    method="post"
                    autoComplete="off"
                    onSubmit={AskHandler}
                    // key={formKey}
                >
                  {/*---------- contractDuration ---------------*/}
                  <Grid item xs={12}>
                    <MKBox p={2} bgcolor="#f5f5f5" borderRadius="lg">
                      <MKTypography variant="body3" style={{fontWeight:700}}>계약 기간 선택 <span style={{ color: 'red' }}> *</span> (VAT 별도이며, 정상가격은 월 4만원입니다.)</MKTypography>
                      <RadioGroup
                          row
                          aria-label="contract-duration"
                          name="contract-duration"
                          value={contractDuration}
                          onChange={handleContractDurationChange}
                      >
                        {/*<FormControlLabel value={1} control={<Radio />} label="1개월 - 2만원" />*/}
                        <FormControlLabel value={6} control={<Radio />} label="6개월 - 18만원" />
                        <FormControlLabel value={12} control={<Radio />} label="12개월 - 24만원" />
                        <FormControlLabel value={24} control={<Radio />} label="24개월 - 21.6만원" />
                      </RadioGroup>
                    </MKBox>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                          label="이름"
                          className="red-asterisk"
                          InputLabelProps={{ shrink: true, style:
                                { color: "black",
                                fontFamily: "Pretendard",
                                fontSize: "18px",
                                fontWeight: 700,},}}
                          fullWidth
                          // onChange={(e) => setMemberName(e.target.value)}
                          value={memberName}
                          onChange={handleNameChange}
                          required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                          label="전화번호"
                          className="red-asterisk"
                          InputLabelProps={{ shrink: true, style:
                                { color: "black",
                                  fontFamily: "Pretendard",
                                  fontSize: "18px",
                                  fontWeight: 700,},}}
                          fullWidth
                          value={memberPhoneNumber}
                          onChange={handlePhoneChange}
                          required
                          placeholder="01012345678"
                          helperText={
                            phoneError
                                ? "전화번호 형식이 올바르지 않습니다. (- 없이 10~11자리 숫자)  ex) 01012345678"
                                : ""
                          }
                          error={phoneError} // MKInput에 error prop이 있는 경우
                          inputProps={{
                            style: {
                              fontFamily: "Pretendard",
                              fontSize: "16px", // 예시 폰트 크기
                              fontWeight: "400", // 예시 폰트 두께
                              fontColor: "black", // 예시 폰트 색상
                              // 기타 필요한 스타일 추가
                            },
                          }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                          label="이메일"
                          className="red-asterisk"
                          InputLabelProps={{ shrink: true, style:
                                { color: "black",
                                  fontFamily: "Pretendard",
                                  fontSize: "18px",
                                  fontWeight: 700,},}}
                          fullWidth
                          value={memberEmail}
                          onChange={handleEmailChange}
                          required
                          placeholder="example@example.com"
                          error={emailError}
                          helperText={
                            emailError
                                ? "이메일 형식이 올바르지 않습니다. ex) example@example.com"
                                : ""
                          }
                      />
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*  <MKInput*/}
                    {/*      type="text"*/}
                    {/*      label="주민등록번호 (선택사항)"*/}
                    {/*      InputLabelProps={{ shrink: true, style:*/}
                    {/*            { color: "black",*/}
                    {/*              fontFamily: "Pretendard",*/}
                    {/*              fontSize: "18px",*/}
                    {/*              fontWeight: 700,},}}*/}
                    {/*      fullWidth*/}
                    {/*      onChange={handleBusinessNumberChange}*/}
                    {/*      value={memberBusinessNumber}*/}
                    {/*      placeholder="000-00-00000"*/}
                    {/*      helperText={*/}
                    {/*        businessNumberError*/}
                    {/*            ? "사업자 등록번호 형식이 올바르지 않습니다. ex) 123-45-67890"*/}
                    {/*            : ""*/}
                    {/*      }*/}
                    {/*      error={businessNumberError}*/}
                    {/*  />*/}
                    {/*  {businessNumberError && (*/}
                    {/*      <Snackbar*/}
                    {/*          open={businessNumberError}*/}
                    {/*          message="사업자 등록번호 형식이 올바르지 않습니다."*/}
                    {/*          autoHideDuration={3000} // 5초 후 자동으로 닫힘*/}
                    {/*      />*/}
                    {/*  )}*/}
                    {/*</Grid>                    */}
                    <Grid item xs={12}>
                      <MKInput
                          type="text"
                          className="red-asterisk"
                          label="주민등록번호"
                          InputLabelProps={{ shrink: true, style:
                                { color: "black",
                                  fontFamily: "Pretendard",
                                  fontSize: "18px",
                                  fontWeight: 700,},}}
                          fullWidth
                          onChange={handleIDNumberChange}
                          value={memberIDNumber}
                          required
                          placeholder="990101-1234567"
                          helperText={
                            idNumberError
                                ? "주민등록번호 형식이 올바르지 않습니다. ex) 990101-1234567"
                                : ""
                          }
                          error={idNumberError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AddressAPI onAddressSelect={handleAddressSelect}/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    {/*---------Drop Down -------*/}
                    {/*<Grid item xs={12} md={6}>*/}
                    {/*  <Box sx={{ minWidth: 120 }}>*/}
                    {/*    <FormControl fullWidth required>*/}
                    {/*      <InputLabel id="select label" shrink>*/}
                    {/*        사업자 유형*/}
                    {/*      </InputLabel>*/}
                    {/*      <Select*/}
                    {/*        labelId="select label"*/}
                    {/*        id="select"*/}
                    {/*        value={contractBusinessType}*/}
                    {/*        label="사업자 유형"*/}
                    {/*        onChange={handleBusinessTypeChange}*/}
                    {/*        sx={{*/}
                    {/*          height: 40,*/}
                    {/*          backgroundColor: "white", // 배경색*/}
                    {/*          color: "black", // 글자색*/}
                    {/*          borderRadius: 1, // 테두리 둥글게*/}
                    {/*          "&:hover": {*/}
                    {/*            backgroundColor: "#f5f5f5", // 호버 시 배경색 변경*/}
                    {/*          },*/}
                    {/*          "& .MuiOutlinedInput-notchedOutline": {*/}
                    {/*            borderColor: "transparent", // 테두리 색상*/}
                    {/*          },*/}
                    {/*        }}*/}
                    {/*        MenuProps={{*/}
                    {/*          sx: {*/}
                    {/*            "& .MuiPaper-root": {*/}
                    {/*              borderRadius: 2, // 드롭다운 메뉴의 테두리 둥글게*/}
                    {/*              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)", // 그림자 효과*/}
                    {/*            },*/}
                    {/*            "& .MuiMenuItem-root": {*/}
                    {/*              "&:hover": {*/}
                    {/*                backgroundColor: "#e0e0e0", // 항목 호버 시 배경색 변경*/}
                    {/*              },*/}
                    {/*            },*/}
                    {/*          },*/}
                    {/*        }}*/}
                    {/*      >*/}
                    {/*        <MenuItem value="" disabled>*/}
                    {/*          사업자 유형 선택*/}
                    {/*        </MenuItem>*/}
                    {/*        <MenuItem value="개인 신규">개인 신규</MenuItem>*/}
                    {/*        <MenuItem value="개인 이전">개인 이전</MenuItem>*/}
                    {/*        <MenuItem value="법인 신규">법인 신규</MenuItem>*/}
                    {/*        <MenuItem value="법인 이전">법인 이전</MenuItem>*/}
                    {/*      </Select>*/}
                    {/*    </FormControl>*/}
                    {/*  </Box>*/}
                    {/*</Grid>*/}

                    {/*----------DatePick-----------*/}
                    <Grid item xs={12} md={6}>
                      <DatePick onDateChange={handleDateChange} key={formKey} />
                    </Grid>
                    {/*-------------Signature----------------*/}
                    <Grid item xs={12}>
                      <Signature onSave={handleSignatureSave} key={formKey}/>

                      {signature ? (
                          <img
                              src={signature}
                              alt="my signature"
                              style={{
                                display: "block",
                                margin: "10px 0",
                                border: "1px solid black",
                                width: "150px",
                                height: "100px",
                                objectFit: "contain",
                              }}
                          />
                      ) : (
                          <div
                              style={{
                                display: "block",
                                margin: "10px 0",
                                border: "1px solid black",
                                width: "150px",
                                height: "100px",
                                backgroundColor: "white",
                              }}
                          />
                      )}
                    </Grid>
                    <AgreementForm onAgreementChange={handleAgreementChange} key={formKey}/>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2} spacing={2}>
                    <Grid item>
                    <MKButton type="submit" variant="gradient" color="info" disabled={isSubmitting}>
                      {isSubmitting ? "제출 중" : "제출하기"}
                    </MKButton>
                    </Grid>
                    <Grid item>
                    <MKButton onClick={handleLoadSavedData} variant="gradient" color="info">
                      작성한 내용 불러오기
                    </MKButton>
                  </Grid>
                  </Grid>
                </MKBox>
              </MKBox>
            </StyledMKBox>
          </Grid>
          <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ImageTextOverlay
                memberName={memberName}
                memberEmail={memberEmail}
                memberPhoneNumber={memberPhoneNumber}
                memberAddress={memberAddress}
                memberIDNumber={memberIDNumber}
                contractPrice={contractPrice}
                contractStartDate={contractStartDate}
                contractDuration={contractDuration}
                signature={signature}
            />
          </Grid>
        </Grid>
      </Bg>
  );
}

export default ContactUs;
const Bg = styled.div`
  background-image: url(${bgImage});
  background-size: 100% 100%;
  background-position: center;
  margin: 0;
  padding: 15px;
  * {
    color: #000;
    border-color: #000 !important;
  }
`;

const StyledMKBox = styled(MKBox)`
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

