import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
  width: 100%; // 기본 너비를 100%로 설정
  max-width: 800px; // 최대 너비를 800px로 설정
  margin: auto; // 컨테이너를 화면 중앙에 위치시킴

  @media (max-width: 1024px) {
    max-width: 600px; // 화면 너비가 1024px 이하일 때 최대 너비를 600px로 조정
  }

  @media (max-width: 768px) {
    max-width: 400px; // 화면 너비가 768px 이하일 때 최대 너비를 400px로 조정
  }
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 50%; // 텍스트 위치 조정
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 14px; // 텍스트 크기 조정
  @media (max-width: 1200px) {
    font-size: 12px; // 화면 너비가 1800px 이하일 때 폰트 크기
  }

  @media (max-width: 900px) {
    font-size: 8px; // 화면 너비가 900px 이하일 때 폰트 크기
  }
`;

const TextOverlay2 = styled(TextOverlay)`
  @media (max-width: 1200px) {
    top: 72%; // 화면 너비가 1024px 이하일 때 위치 조정
  }

  //@media (max-width: 768px) {
  //  top: 70%; // 화면 너비가 768px 이하일 때 위치 조정
  //  left: 50%; // 위치 조정
  //}
`;

function ImageTextOverlay({memberName, memberEmail, memberPhoneNumber, memberAddress, memberIDNumber, contractPrice, contractStartDate, contractDuration, signature}) {

    const startDate = new Date(contractStartDate);
    const currentDate = new Date();
    const duration = parseInt(contractDuration, 10);
    const price = parseInt(contractPrice, 10);
    const monthPrice = price / duration;

    let endDate = new Date(startDate);
    endDate.setMonth(startDate.getMonth() + duration);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const contractYear = startDate.getFullYear();
    const contractMonth = startDate.getMonth() + 1;
    const contractDay = startDate.getDate();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endDay = endDate.getDate();

    const formattedTotalPrice = new Intl.NumberFormat('ko-KR').format(price);
    const formattedMonthlyPrice = new Intl.NumberFormat('ko-KR').format(monthPrice);

    return (
        <div>
            <ImageContainer>
                <img src="/TenSecondsContract4.jpg" alt="Contract" style={{ width: '100%', height: 'auto' }} />
                {/*계약정보*/}
                <TextOverlay2 style={{ top: '82.4%' ,left:'40%'}}>{memberName}</TextOverlay2>
                <TextOverlay style={{ top: '82.4%' ,left:'80%' }}>{memberEmail}</TextOverlay>
                <TextOverlay style={{ top: '80.35%', left:'80%' }}>{memberPhoneNumber}</TextOverlay>
                <TextOverlay style={{ top: '78.2%', left:'60%' }}>{memberAddress}</TextOverlay>
                <TextOverlay style={{ top: '80.35%', left:'40%' }}>{memberIDNumber}</TextOverlay>
                <TextOverlay style={{ top: '30.6%', left:'33%' }}>{formattedTotalPrice} 원</TextOverlay>
                <TextOverlay style={{ top: '28.7%', left:'33%' }}>{formattedMonthlyPrice} 원</TextOverlay>
                {/*계약날짜*/}
                <TextOverlay style={{ top: '34.35%', left: '33.7%' }}>{contractYear}</TextOverlay>
                <TextOverlay style={{ top: '34.35%', left: '39.6%' }}>{contractMonth}</TextOverlay>
                <TextOverlay style={{ top: '34.35%', left: '44%' }}>{contractDay}</TextOverlay>
                <TextOverlay style={{ top: '34.35%', left: '54%' }}>{endYear}</TextOverlay>
                <TextOverlay style={{ top: '34.35%', left: '59.8%' }}>{endMonth}</TextOverlay>
                <TextOverlay style={{ top: '34.35%', left: '64.3%' }}>{endDay}</TextOverlay>
                {/*오늘날짜*/}
                <TextOverlay style={{ top: '69.35%', left: '38.5%' }}>{currentYear}</TextOverlay>
                <TextOverlay style={{ top: '69.35%', left: '47%' }}>{currentMonth}</TextOverlay>
                <TextOverlay style={{ top: '69.35%', left: '54%' }}>{currentDay}</TextOverlay>
                {/*계약 기간*/}
                <TextOverlay style={{ top: '30.6%', left:'55%'}}>{contractDuration}</TextOverlay>
                <TextOverlay style={{ top: '34.35%', left:'73%'}}>{contractDuration}</TextOverlay>
                {/*서명*/}
                {signature && (
                    <img src={signature} alt="Signature" style={{position: 'absolute',
                            top: '82.5%',
                            left: '53%',
                            transform: 'translate(-50%, -50%)',
                            maxWidth: '100px',
                            maxHeight: '100px',
                        }}
                    />
                )}
                <TextOverlay style={{ top: '96%', left:'50%'}}>※위 계약서는 예시로 임대부분은 실제 계약서에 표기됩니다.</TextOverlay>
                <TextOverlay style={{ top: '97.5%', left:'50%'}}>※모바일 환경에서는 간격이 안 맞을 수 있으나, 실제 계약서에는 바르게 표기됩니다.</TextOverlay>
            </ImageContainer>
        </div>
    );
}

ImageTextOverlay.propTypes = {
    memberName: PropTypes.string,
    memberEmail: PropTypes.string,
    memberPhoneNumber: PropTypes.string,
    memberAddress: PropTypes.string,
    memberIDNumber: PropTypes.string,
    // memberBusinessNumber: PropTypes.string,
    contractPrice: PropTypes.string,
    contractStartDate: PropTypes.instanceOf(Date),
    contractDuration: PropTypes.string,
    signature: PropTypes.string,
};

export default ImageTextOverlay;
