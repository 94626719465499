// import PropTypes from 'prop-types'; // PropTypes 임포트
// import React from "react";
// import { Routes, Navigate } from "react-router-dom";
// import { useAuth } from "./AuthContext"; // 예시로 사용하는 Auth 컨텍스트
//
// // const PrivateRoute = ({ component: Component, ...rest }) => {
// //     const { currentUser } = useAuth(); // 현재 로그인 상태를 가져옵니다.
// //
// //     return (
// //         <Routes
// //             {...rest}
// //             render={props =>
// //                 currentUser ? <Component {...props} /> : <Navigate to="/" />
// //             }
// //         />
// //     );
// // };
//
// const PrivateRoute = () => {
//     const { currentUser } = useAuth();
//
//     return currentUser ? <Routes /> : <Navigate to="/" />;
// }
//
// // PropTypes 정의
// // PrivateRoute.propTypes = {
// //     component: PropTypes.elementType.isRequired // component가 React 컴포넌트 타입임을 명시
// // };
//
// export default PrivateRoute;


import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PrivateRoute = () => {
    const { pathname } = useLocation();
    const { currentUser } = useAuth();

    if(pathname === "/admin" || pathname === "/dashboard" ) {
        return currentUser ? <Outlet/> : <Navigate to="/admin"/>;
    }

    return <Outlet />;
};

export default PrivateRoute;