import React from 'react';

function PrivacyPolicyContent() {
        const policyText = `
제1조(목적)
본 개인정보처리방침은 텐마일즈(이하 '회사')가 제공하는 서비스 텐세컨즈(이하 '서비스') 이용과정에서 이용자의 정보를 어떻게 수집, 이용, 보관, 파기하는지에 대한 정보를 명시함으로써 이용자의 권리와 이익을 보호하는 데 그 목적이 있습니다.

제2조 (개인정보의 수집 항목 및 이용 목적)
회사는 서비스 제공을 위해 필요한 최소한의 개인정보를 수집합니다. 수집하는 개인정보는 다음과 같습니다.
필수항목: 이름, 전화번호, 이메일, 주소
선택항목: 사업자등록번호
수집된 개인정보는 서비스 제공, 계약의 이행 및 관리, 고객 문의 응대 등의 목적으로 이용됩니다.

제3조 (개인정보의 보유 및 이용 기간)
이용자의 개인정보는 계약 종료 후 최대 3년까지 보유하며, 이후 해당 정보는 안전하게 파기됩니다. 다만, 관련 법령에 따른 정보 보유 사유가 발생하는 경우 관련 법령에서 요구하는 기간 동안 개인정보를 보유합니다.

제4조 (개인정보의 제3자 제공 및 공유)
회사는 이용자의 개인정보를 이용자의 동의 없이 제3자에게 제공하지 않습니다. 법령에 따라 제공해야 하는 경우를 제외하고는 개인정보를 외부에 공개하지 않습니다.

제5조 (이용자의 권리)
이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 개인정보의 삭제 또는 가입 해지를 요청할 수 있습니다.

제6조 (개인정보의 파기 절차 및 방법)
개인정보의 처리 목적 달성 후 또는 보유 기간 만료 시에는 해당 정보를 지체 없이 파기합니다. 전자적 파일 형태의 정보는 기술적 방법을 사용하여 영구 삭제하고, 종이 문서는 분쇄하거나 소각하여 파기합니다.

제7조 (쿠키의 운영 및 거부)
회사는 서비스 개선과 맞춤형 서비스 제공을 위해 쿠키를 운영합니다. 이용자는 쿠키 설치를 거부할 수 있으며, 거부 시 일부 서비스의 이용에 제약이 있을 수 있습니다.

제8조 (개인정보 보호책임자)
회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
이      름  : 조훈상
소속 / 직위  : 텐마일즈 / 대표이사
E-MAIL  : tenmiles0403@naver.com
전 화 번 호  : 010-2261-9560
평일 10시~5시, 주말/공휴일휴무
부칙
본 개인정보처리방침은 2024년 03월 04일부터 시행됩니다.

귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
1.개인분쟁조정위원회 (www.1336.or.kr/1336)
2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)
3.대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)
4.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)`;

        return (
        <div>
            {policyText}
        </div>
    );
}

export default PrivacyPolicyContent;