import React, { Suspense } from "react";
import styled from "styled-components";
// import KakaoMap from "../KakaoMap";

const KakaoMap = React.lazy(() => import("../KakaoMap"));
function Map() {
  return (
    <Container>
      <Contents>
        <Top>
          <Title>위치 안내</Title>
        </Top>
          <Suspense fallback={<div>Loading...</div>}>
              <KakaoMap />
          </Suspense>
      </Contents>
    </Container>
  );
}

export default Map;
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background: #ffffff;
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: center;
`;
const Title = styled.p`
  color: #303030;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.88px;
  line-height: 60px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 51px;
  }
`;
