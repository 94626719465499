// import "./index.css";
// import React from "react";
// import styled from "styled-components";
// // import Hero from "./sections/Hero";
// import Prices from "./sections/Prices";
// import Black from "./sections/Black";
// import Who from "./sections/Who";
// // import Image from "./sections/Image";
// import Procedures from "./sections/Procedures";
// import DefaultFooter from "examples/Footers/DefaultFooter";
// import footerRoutes from "footer.routes";
// import Map from "./sections/Map";
// import Notice from "./sections/Notice";
// import FAQ from "./sections/FAQ";
//
// function Page() {
//   return (
//     <Container>
//       {/*<Hero />*/}
//       {/*<Image />*/}
//       <Notice />
//       <Who />
//       <Black />
//       <Procedures />
//       <Prices />
//       <FAQ />
//       <Map />
//       <DefaultFooter content={footerRoutes} />
//     </Container>
//   );
// }
//
// export default Page;
//
// const Container = styled.div`
//   width: 100%;
//   background: #fff;
// `;

import "./index.css";
import React from "react";
import styled from "styled-components";
import Hero from "./sections/Hero";
import Advantages from "./sections/Advantages";
import Prices from "./sections/Prices";
// import Black from "./sections/Black";
import Who from "./sections/Who";
// import Image from "./sections/Image";
import Procedures from "./sections/Procedures";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Map from "./sections/Map";
// import Notice from "./sections/Notice";
import FAQ from "./sections/FAQ";
import Last from "./sections/Last";
// import { FaClock } from "react-icons/fa"; // Example icon
import { GoStopwatch } from "react-icons/go";

function Page() {
  return (
    <Container>
      <Hero />
      <Contents>
        <Seconds>
          {["10초", "9초", "8초", "7초", "6초", "5초", "4초", "3초", "2초", "1초"].map((second) => (
            <Second key={second}>
              <StyledGoStopwatch />
              {second}
            </Second>
          ))}
        </Seconds>
        {/*<Image />*/}
        <Who />
        <Advantages />
        <Procedures />
        {/*<NumberedComponent number={9+"초"}><Notice/></NumberedComponent>*/}
        {/*<NumberedComponent number={8+"초"}><Black/></NumberedComponent>*/}
        <Prices />
        <Last />
      </Contents>
      <FAQ />
      <Map />
      <DefaultFooter content={footerRoutes} />
    </Container>
  );
}

export default Page;

const Container = styled.div`
  width: 100%;
  background: #fff;
`;
const Contents = styled.div`
  width: 100%;
  position: relative;
`;
const Seconds = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 10px;
  padding-bottom: 150px;
  margin-top: 30px;
  @media (max-width: 768px) {
    left: 4px;
  }
`;
const Second = styled.p`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 32px;
  text-align: left;
  left: 15%;
  color: #6A88BE;
  &:not(:last-child)::before {
    content: ''; // 가상 요소는 content 속성이 필요
    position: absolute;
    top: 115px; // 위쪽으로 얼마나 떨어뜨릴지
    left: 50%; // 가운데 정렬
    width: 2px; // 선의 두께
    height: 500px; // 선의 길이
    background-color: #a9a9e3; // 선의 색상
    transform: translateX(-50%); // 정확히 중앙으로 이동
}
  @media (max-width: 1200px) {
    display: none;
    //scale: 0.5;
  }  
    @media (max-width: 768px) {
    display: none;
    scale: 0.7;
    &:not(:last-child)::before {
      display: none; /* 화면이 작아졌을 때 선을 숨김 */
  }
`;

const StyledGoStopwatch = styled(GoStopwatch)`
margin-bottom:10px;
`;

