import { useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export default function useInViewAnimation(amount, once = true) {
  const control = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: once, amount: amount });
  useEffect(() => {
    if (isInView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, isInView]);
  return [ref, control];
}
