// import React from "react";
// import styled from "styled-components";
// import PropTypes from "prop-types";
//
// const Card = styled.div`
//   background: #fff;
//   padding: 20px;
//   margin-bottom: 60px;
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   gap: 15px;
//   //position: relative;
// `;
//
// const CardTitle = styled.p`
//   color: #333;
//   font-size: 24px;
//   font-weight: 700;
// `;
//
// const CardText = styled.p`
//   color: #666;
//   white-space: pre-line;
// `;
//
// const AdvantageCard = ({ title, text }) => {
//   return (
//     <Card>
//       <div>
//         <CardTitle>{title}</CardTitle>
//         <CardText>{text}</CardText>
//       </div>
//     </Card>
//   );
// };
//
// AdvantageCard.propTypes = {
//   title: PropTypes.string.isRequired,
//   text: PropTypes.string.isRequired,
// };
//
// export default AdvantageCard;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Card = styled.div`
  width: 100%;
  //background: #fff;
  padding: 20px;
  margin-bottom: 100px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 15px;
  background: #f5f5f7;
  //position: relative;
`;

// const CardText = styled.p`
//   color: #666;
//   white-space: pre-line;
// `;

const AdvantageCard = ({ children }) => {
  return (
    <Card>
      <div>
        {children}
      </div>
    </Card>
  );
};

AdvantageCard.propTypes = {
    children: PropTypes.node.isRequired,
    // image: PropTypes.string,
};

export default AdvantageCard;
