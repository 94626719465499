import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
// import { utcToZonedTime } from 'date-fns-tz';

function DatePick({ onDateChange }) {
    const [startDate, setStartDate] = useState(new Date());

    const handleDateChange = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // 시간을 00:00:00으로 설정

        // const today2 = utcToZonedTime(today, 'Asia/Seoul');

        // console.log("UTC check :" + startDate + " / today " + today + " / today2 " + today2);

        if (date < today) {
            alert('계약 시작일은 오늘 이전일 수 없습니다.');
            setStartDate(today);
            onDateChange(today); // 콜백 함수 호출
        } else {
            setStartDate(date);
            onDateChange(date); // 콜백 함수 호출
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            event.preventDefault(); // 기본 동작 방지

            const today = new Date();
            today.setHours(0, 0, 0, 0); // 오늘 날짜를 00:00:00으로 설정

            const proposedDate = new Date(startDate);
            proposedDate.setDate(proposedDate.getDate() + (event.key === "ArrowUp" ? 1 : -1));

            if (proposedDate < today) {
                alert('계약 시작일은 오늘 이전일 수 없습니다.');
            } else {
                setStartDate(proposedDate);
                onDateChange(proposedDate);
            }
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                format="yyyy-MM-dd"
                label={<><span style={{ fontFamily: "Pretendard", fontSize: "18px", fontWeight: 700 }}>계약 시작일</span><span style={{ color: 'red' }}> *</span></>}
                value={startDate}
                onChange={handleDateChange}
                minDate={new Date()}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true, style:
                                { color: "black",
                                    fontFamily: "Pretendard",
                                    fontSize: "18px",
                                    fontWeight: 700,},}}
                        inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            onKeyDown : handleKeyDown
                            // onKeyDown: (event) => {
                            //     if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                            //         event.preventDefault();
                            //     }
                            // }
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
}

DatePick.propTypes={
    onDateChange:PropTypes.func.isRequired,
};

export default DatePick;