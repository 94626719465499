import React from "react";
import styled from "styled-components";
import WhoCard from "../components/WhoCard";
import useInViewAnimation from "hooks/useInViewAnimation";
import { FaUserFriends } from "react-icons/fa";
// import addressImage from 'assets/images/free-icon-address.png';
import hybridworkImage from 'assets/images/free-icon-hybrid-work.png';
import mailImage from 'assets/images/free-icon-mailing.png';
import officeImage from 'assets/images/free-icon-office.png';
import pensionImage from 'assets/images/free-icon-pension.png';

function Who() {
  const getVariant = (index) => ({
    visible: { opacity: 1, transition: { duration: 0.2, delay: index * 0.15 } },
    hidden: { opacity: 0 },
  });
  const [ref, control] = useInViewAnimation(0.5);
  return (
    <Container ref={ref}>
        <IntroSection>
      <Title underline={true}>비상주 사무실이란?</Title>
      <Text>
          비상주 사무실은 물리적인 사무 공간을 실제로 사용하지 않으면서 <br/>사업자등록 상의 주소만 필요하신 분들을 위해,<br /><BoldText>주소지를 제공해드리는 사업장 주소 임대 서비스</BoldText>입니다.
          <br/><br/>
      </Text>
            <SubText>*집이나 사무실 없이 합법적인 사업자 주소를 확보할 수 있는 서비스입니다.</SubText>
        </IntroSection>
      <SecondSection>
      <TitleWithIcon>
        <FaUserFriends size={50} color="#4278f5" />
        <Title>
          비상주 사무실, <br/>
          <Br />
          <TitleSpan> 이런 </TitleSpan>분들에게 필요합니다
        </Title>
      </TitleWithIcon>
      <AdvantageList>
        <Row>
          <WhoCard
            img={officeImage}
            alt="officeImage"
            // title="즉시 계약, 즉시 시작"
            desc={`사업자등록을 해야 하는데 오프라인 사무실은 필요 없으신 분`}
            animate={control}
            initial="hidden"
            variants={getVariant(0)}
          />
          <WhoCard
            img={mailImage}
            alt="mailImage"
            // title="100% 비대면 계약 프로세스"
            desc={`자택이 아닌 다른 곳에서 우편물을 받으셔야 하는 분`}
            animate={control}
            initial="hidden"
            variants={getVariant(1)}
          />
        </Row>
        <Row>
          <WhoCard
            img={pensionImage}
            alt="pensionImage"
            // title="신뢰와 안정성의 임대차 계약"
            desc={`초기 비용을 줄이고 싶은 신규 사업자`}
            animate={control}
            initial="hidden"
            variants={getVariant(2)}
          />
          <WhoCard
            img={hybridworkImage}
            alt="hybridworkImage"
            // title="계약서 즉시 수령"
            desc={`주 사업장 이외에 별도의 주소지가 필요하신 분`}
            animate={control}
            initial="hidden"
            variants={getVariant(3)}
          />
        </Row>
      </AdvantageList>
          </SecondSection>
    </Container>
  );
}

export default Who;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #fff, #f6f6f8 24.48%, #f5f5f7);
  //display: flex;
  //flex-direction: column;
  //padding-bottom: 149px;
  //padding-top: 100px;
`;
const Title = styled.p`
  color: #303030;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -0.875px;
  line-height: 60px;
  text-align: left;
  margin-bottom: 30px;
  text-decoration: ${({ underline }) => underline ? 'underline' : 'none'};
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
  }
`;
const TitleSpan = styled.span`
  color: #4278f5;
`;
const AdvantageList = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Row = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 1100px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Text = styled.p`
  width: 80vw;
  color: #303030;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.88px;
  line-height: 40px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
  text-align: center;
  word-break: keep-all;
`;

const SubText = styled.p`
  width: 80vw;
  color: #949ca8;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.88px;
  line-height: 40px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
  text-align: center;
  word-break: keep-all;
`;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px; // 아이콘과 텍스트 사이의 간격 조정
  margin-top: 100px;
  margin-bottom: 30px;
`;
const Br = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const SecondSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; /* 배경색 지정 */
  width: 100%;
  //padding: 50px 0; /* 상하 패딩으로 내용과 경계 간격 추가 */
  box-sizing: border-box;
  padding-bottom: 100px;
`;

const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 100px;
`;

const BoldText = styled.span`
    font-weight: 900;
    `;