export const toastConfig = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
        background: "#F5F5F5",
        color: "#333333",
        fontSize: "16px",
        textAlign: "center",
        borderRadius: "8px",
        padding: "20px",
        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
        width: "500px",
    },
};