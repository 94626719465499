import React, { useState, useEffect, useCallback } from "react";
// import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import logo from "assets/images/tenseconds2.png";
import styled from "styled-components";
import { useLocation, Link as RouterLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";

const Navbar = () => {
  const [showNav, setShowNav] = useState(true);
  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;

      // if (y > window.scrollY || window.scrollY < 10) {
      if (window.scrollY < 10) {
        setShowNav(true);
      } else if (y < window.scrollY) {
        setShowNav(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
    }, [handleNavigation]);

    const location = useLocation();

    const isHiddenPage = location.pathname === "/pages/landing-pages/contact-us" ||
        location.pathname === "/dashboard" ||
        location.pathname === "/admin";

    const handelLogoClick= (e) => {
        if(location.pathname === '/'){
            e.preventDefault();
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    };

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <AppBar
      position="fixed"
      sx={{
        // top: showNav ? 0 : "-64px",
        top: 0,
        backgroundColor: showNav ? "transparent" : "#fff",
        boxShadow: "none",
        transition: "0.3s ease",
        borderBottom: showNav ? "none" : "1px solid #ddd",
          '@media (max-width: 600px)': {
                backgroundColor: "#fff",
                borderBottom: "1px solid #ddd",

          },
      }}
    >
      <Toolbar>
          <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                  mr: 2,
                  display: { xs: isHiddenPage ? 'none' : 'block', sm: 'none' }, // '계약하기' 페이지에서는 숨김
                  color: 'black'
              }}
              onClick={() => setDrawerOpen(true)}
          >
              <MenuIcon />
          </IconButton>
          <Link component={RouterLink} to="/" onClick={handelLogoClick} style={{display: 'flex', alignItems: 'center'}}>
              <img src={logo} alt="TenSec 로고" style={{height: '28px'}} />
          </Link>
        {/*<Link*/}
        {/*  to="/"*/}
        {/*  style={{*/}
        {/*    display: "flex",*/}
        {/*    alignItems: "center",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <img src={logo} alt="TenSec 로고" style={{ height: "28px" }} />*/}
        {/*</Link>*/}
        {/*<div style={{ marginLeft: "auto" }}>*/}
        <CustomButtonContainer>
            {!isHiddenPage && (
                <>
          <CustomButton showNav={showNav} onClick={() => scrollToSection('pricingSection')}>요금 안내</CustomButton>
          <CustomButton showNav={showNav} onClick={()=> scrollToSection('faqSection')}>자주 묻는 질문</CustomButton>
          <CustomButton showNav={showNav} to="/pages/landing-pages/contact-us">계약하기</CustomButton>
                </>
          )}
        </CustomButtonContainer>
      </Toolbar>
        <Drawer
            anchor={'top'}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
        >
            <Box
                sx={{
                    width: 'auto',
                    display: 'flex',
                    flexDirection: 'column', // 수직으로 메뉴 항목을 나열
                    padding: '16px' // 여백 추가
                }}
            >
                <CustomButton onClick={() => scrollToSection('pricingSection')}>요금 안내</CustomButton>
                <CustomButton onClick={() => scrollToSection('faqSection')}>자주 묻는 질문</CustomButton>
                <CustomButton onClick={() => { setDrawerOpen(false); window.location.href = '/pages/landing-pages/contact-us'; }}>계약하기</CustomButton>
            </Box>
        </Drawer>
    </AppBar>
  );
};

export default Navbar;
const CustomButton = styled(Link)`
  //background: #4278f5;
  border-radius: 31px;
  //color: #fff;
  color: ${({ showNav }) => (showNav ? 'white' : 'black')};
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  margin-right : 10px;
  &:not(:last-child){
    margin-right: 50px;
  }
  &:hover {
    background: linear-gradient(90deg, #4278f5, #9342f5 105.41%);
    //box-shadow: 8px 8px 117px 0.5px #e0dfe5;
    box-shadow: 8px 8px 117px 0.5px #e0dfe5;
    color: #fff;
  }

  @media (max-width: 800px) {
    font-size: 14px; // 핸드폰 화면에서 폰트 크기 조정
    padding: 4px 8px; // 핸드폰 화면에서 패딩 조정
    margin-right: 5px; // 핸드폰 화면에서 마진 조정
    height: 32px; // 핸드폰 화면에서 높이 조정
    line-height: 32px; // 핸드폰 화면에서 라인 높이 조정
    &:not(:last-child){
      margin-right: 20px;
    }
  }
`;

const CustomButtonContainer = styled.div`
  //display:flex;
  margin-left: 85px; // 기본 마진 설정

  @media (max-width: 600px) {
    margin-left: 10px; // 화면 너비가 768px 이하일 때 마진 조정
    display: none;
  }

  @media (max-width: 480px) {
    margin-left: 20px; // 화면 너비가 480px 이하일 때 마진 조정
  }
`;