import React from "react";
import styled from "styled-components";
import HeroButton from "../components/HeroButton";
import useInViewAnimation from "hooks/useInViewAnimation";
import { motion } from "framer-motion";

const backgroundImage = "/mainoffice6.jpg";
function Hero() {
    const variant = {
        visible: { opacity: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0 },
    };
    const [ref, control] = useInViewAnimation(0);
    return (
        <Container ref={ref} animate={control} variants={variant} initial="hidden">
            <Title>10초 안에 <br />대한민국 &ldquo;진짜&ldquo; 최저가 <br />비상주 사무실 계약하기</Title>
            <Text>실시간 원스톱 계약 서비스</Text>
            <Desc>
                {/*<DescSpan>TenSeconds</DescSpan>에서 시작하세요.*/}
                {/*<br />단 10초면 사업 공간이 준비됩니다!*/}
                <DescSpan></DescSpan>
                <br />
            </Desc>
            <HeroButton text={"지금 바로 계약하러 가기"}/>
        </Container>
    );
}

export default Hero;

const Container = styled(motion.div)`
  width: 100%;
  max-height: 100vh;
  display: flex;
  padding: 128px 0;
  flex-direction: column;
  align-items: center;
  background-image: url(${backgroundImage}); /* 배경 이미지 추가 */
  background-size: cover; /* 배경 이미지 크기 조절 */
  background-position: center; /* 배경 이미지 위치 조절 */
  margin-bottom: 200px;

`;
const Title = styled.p`
  background-image: -webkit-linear-gradient(0deg, #ffffff, #ffffff);
  display: inline-block;
  font-size: 64px;
  font-weight: 800;
  line-height: 84px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 80px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 60px;
  }
`;
const Desc = styled.p`
  color: #686d79;
  font-size: 31.39px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 10px;
  margin-top: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    margin-top: 40px;
  }
`;
const Text = styled.p`
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.88px;
  line-height: 60px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 51px;
  }
`;

const DescSpan = styled.span`
  color: #4278f5;
`;