import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const MemberModal = ({memberData, onClose}) => {
    if (!memberData) return null;

    console.log("memberData : " + memberData)
    console.log("memberData : " + memberData.memberName)
    // 오버레이 스타일
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    };

    // 모달창 스타일
    const modalStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        width: '500px',
        height: '220px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative',
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
    };


    return (
        <div style={overlayStyle} onClick={onClose}>
            <div style={modalStyle} onClick={e => e.stopPropagation()}>
                <div style={{ textAlign: 'right' }}> {/* 오른쪽 정렬을 위한 컨테이너 */}
                    <Button style={closeButtonStyle} onClick={onClose}>닫기</Button>
                </div>
                <h2>회원 정보</h2>
                <br/>
                <p>이름: {memberData.memberName}</p>
                <p>이메일: {memberData.memberEmail}</p>
                <p>전화번호: {memberData.memberPhonenumber}</p>
                <p>주소: {memberData.memberAddress}</p>
            </div>
        </div>
    );
};

MemberModal.propTypes = {
    memberData: PropTypes.shape({
        memberName: PropTypes.string,
        memberEmail: PropTypes.string,
        memberPhonenumber: PropTypes.string,
        memberAddress: PropTypes.string,
        // 여기에 memberData 객체의 다른 속성 타입도 정의할 수 있습니다.
    }),
    onClose: PropTypes.func.isRequired, // 필수 함수 prop에 대한 타입 정의
};

export default MemberModal;