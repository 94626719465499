import React, { useRef, useState } from "react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import MKButton from "components/MKButton";
function Signature({ onSave }) {
  const sigCanvas = useRef({});
  const [buttonText, setButtonText] = useState("서명하기"); // 버튼 텍스트 상태
  const [buttonVariant, setButtonVariant] = useState("outlined");
  const [buttonTextColor, setButtonTextColor] = useState("black");

  const signaturePadOptions = {
    minWidth: 3, // 최소 굵기
    maxWidth: 5, // 최대 굵기
    penColor: "black",
  };

  const signaturePadStyle = {
    border: "2px solid black",
    width: "700px",
    minHeight: "400px",
    backgroundColor: "white",
  };

  Signature.propTypes = {
    onSave: PropTypes.func.isRequired,
  };

  const save = (close) => {
    if (sigCanvas.current) {
      const signature = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      setButtonText("서명완료");
      setButtonVariant("contained");
      setButtonTextColor("white");
      onSave(signature);
      close();
    }
  };

  return (
    <div className="signature">
      <div style={{ fontSize: "0.9rem", marginBottom: "1rem", fontWeight: 700 }}>서명하기 <span style={{ color: 'red' }}>*</span></div>
      <Popup
        modal
        contentStyle={{
          width: "auto",
          maxWidth: "600px",
          position: "fixed",
          bottom: "20%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        trigger={
          <Button variant={buttonVariant} style={{ color: buttonTextColor }}>
            {buttonText}
          </Button>
        }
        closeOnDocumentClick={false}
      >
        {(close) => (
          <>
            <SignaturePad
              canvasProps={{ style: signaturePadStyle }}
              ref={sigCanvas}
              {...signaturePadOptions}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <MKButton variant="gradient" color="info" onClick={close}>
                닫기
              </MKButton>
              <MKButton variant="gradient" color="info" onClick={() => save(close)}>
                저장
              </MKButton>
            </div>
          </>
        )}
      </Popup>
    </div>
  );
}

export default Signature;
