import * as React from 'react';
// import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import {useEffect, useState} from "react";
import MemberModal from "./MemberModal";
import PaymentInfoModal from "./PaymentInfoModal";
import {toast} from 'react-toastify';
import MKButton from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {confirmAlert} from 'react-confirm-alert';
// import '../custom-react-confirm-alert.css';
import Box from "@mui/material/Box";
import { Tabs, Tab} from '@mui/material';

// function preventDefault(event) {
//     event.preventDefault();
// }

export default function Orders() {
    const [contract, setContract] = useState([]);
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
// 결제 완료된 계약만 필터링
    const completedContracts = contract.filter(c => c.contractStatus === '결제 완료');


    const fetchMemberData = async (memberPk) => {
        try {
            // memberPk를 문자열로 변환
            const memberPkString = String(memberPk);

            const response = await fetch(`/api/contract/members/${memberPkString}`);
            if (!response.ok) {
                throw new Error('서버에서 데이터를 가져오는 데 실패했습니다.');
            }
            const memberData = await response.json();
            return memberData;
        } catch (error) {
            console.error('회원 데이터 가져오기 실패:', error);
        }
    };

    const handleMemberClick = async (memberPk) => {
        const memberData = await fetchMemberData(memberPk);
        if (memberData) {
            setSelectedMember(memberData);
            openModal('memberInfo');
        }
    };
    const fetchPaymentData = async (billId) => {
        try {
            const response = await fetch(`/api/payment-info/${billId}`);
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('결제 내역을 찾을 수 없습니다.');
                } else {
                    throw new Error('결제 내역 데이터 가져오기 실패');
                }
            }
            const paymentData = await response.json();
            return paymentData;
        } catch (error) {
            console.error('결제 내역 데이터 가져오기 실패:', error);
        }
    };

    const handlePaymentInfoClick = async (billId) => {
        const paymentData = await fetchPaymentData(billId);
        if (paymentData) {
            setSelectedPaymentInfo(paymentData);
            console.log('selectedPaymentInfo:', paymentData);
            openModal('paymentInfo');
        }
    };
    const openModal = (type) => {
        setModalType(type);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalType(null);
    };

    useEffect(() => {
        fetch('/api/contract')
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                setContract(data);
            })
    }, []);

    useEffect(() => {
        if (tabValue === 1) {
            fetchMembersData();
        }
    }, [tabValue]);

    const fetchMembersData = async () => {
        try {
            const response = await fetch('/api/member');
            if (!response.ok) {
                throw new Error('서버에서 데이터를 가져오는 데 실패했습니다.');
            }
            const data = await response.json();
            // console.log(data);
            setMembers(data);
        } catch (error) {
            console.error('회원 데이터 가져오기 실패:', error);
        }
    };

// 바이너리 데이터를 PDF 뷰어로 전환
    const createDownloadLink = (contractPk) => {
        const url = `/api/contract/download/${contractPk}`;
        return <a href={url} target="_blank" rel="noopener noreferrer">View PDF</a>;
    };

    // 바이너리 데이터를 다운로드 링크로 변환
    // const createDownloadLink = (contractPk, fileName) => {
    //     const url = `/api/contract/download/${contractPk}`;
    // return <a href={url} download={fileName}>Download</a>;


    const updateContractStatus = (contractBillId) => {
        setContract((prevContracts) => prevContracts.map((contract) => {
            if (contract.contractBillId === contractBillId) {
                // 해당 계약의 contractStatus를 '결제 취소'로 업데이트
                return { ...contract, contractStatus: '결제 취소' };
            }
            return contract;
        }));
    };


    const formatDate = (billId) => {
        const dateString = billId.slice(0, 12);
        const year = dateString.slice(0, 2);
        const month = dateString.slice(2, 4);
        const day = dateString.slice(4, 6);
        const hour = dateString.slice(6, 8);
        const minute = dateString.slice(8, 10);
        return `20${year}-${month}-${day} ${hour}:${minute}`;
    };

    // 일별 총매출 계산
    const dailySales = completedContracts.reduce((sales, contract) => {
        const date = formatDate(contract.contractBillId).split(' ')[0];
        sales[date] = (sales[date] || 0) + contract.contractPrice;
        return sales;
    }, {});

// 월별 총매출 계산
    const monthlySales = completedContracts.reduce((sales, contract) => {
        const month = formatDate(contract.contractBillId).slice(0, 7);
        sales[month] = (sales[month] || 0) + contract.contractPrice;
        return sales;
    }, {});

//     // 일별 총매출 출력
//     console.log('일별 총매출:');
//     for (const date in dailySales) {
//         console.log(`${date}: ${dailySales[date]}원`);
//     }
//
// // 월별 총매출 출력
//     console.log('월별 총매출:');
//     for (const month in monthlySales) {
//         console.log(`${month}: ${monthlySales[month]}원`);
//     }

    const getStatusColor = (status) => {
        switch (status) {
            case '결제 완료':
                return 'blue';
            case '결제 취소':
                return 'red';
            case '결제 파기':
                return 'gray';
            default:
                return 'black';
        }
    };

    const resendEmail = async (contractPk, memberName) => {
        confirmAlert({
            title: '이메일 재발송',
            message: (
                <>
                    [{memberName}] 회원에게<br />
                    이메일을 재발송하시겠습니까?
                </>
            ),
            buttons: [
                {
                    label: '확인',
                    onClick: async () => {
                        const toastId = toast.loading("메일 발송중입니다...");

                        try {
                            const response = await fetch(`/api/contract/sendEmail/${contractPk}`, {method: 'POST'});
                            if (response.ok) {
                                toast.dismiss(toastId);
                                toast.success("이메일 재발송 성공!", {
                                    position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    style: {
                                        background: "#333",
                                        color: "#fff",
                                        fontSize: "16px",
                                        textAlign: "center",
                                        borderRadius: "8px",
                                        padding: "20px",
                                        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
                                        width: "500px",
                                    }
                                });
                            } else {
                                throw new Error('서버 에러');
                            }
                        } catch (error) {
                            toast.dismiss(toastId);
                            toast.error("이메일 재발송에 실패했습니다.", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                style: {
                                    background: "#333",
                                    color: "#fff",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    borderRadius: "8px",
                                    padding: "20px",
                                    boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
                                    width: "500px",
                                }
                            });
                        }
                    }
                },
                {
                    label: '취소',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const cancelPayment = async (contractBillId, contractPrice, memberName) => {
        confirmAlert({
            title: '결제 취소',
            message: `[${memberName}] 회원의 결제를 취소하시겠습니까?`,
            buttons: [
                {
                    label: '확인',
                    onClick: async () => {
                        const toastId = toast.loading("결제 취소 중입니다...");

                        try {
                            const response = await fetch(`/payment/cancel`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    bill_id: contractBillId,
                                    price: contractPrice,
                                }),
                            });

                            if (response.ok) {
                                const result = await response.text();
                                if (result === "Payment Cancel Success") {
                                    toast.dismiss(toastId);
                                    toast.success("결제 취소 성공!", {
                                        // ... toast options ...
                                    });
                                    // 결제 취소 후 계약 상태 업데이트 등의 추가 작업 수행
                                    updateContractStatus(contractBillId);
                                } else {
                                    throw new Error('결제 취소 실패');
                                }
                            } else {
                                throw new Error('서버 에러');
                            }
                        } catch (error) {
                            toast.dismiss(toastId);
                            toast.error("결제 취소에 실패했습니다.", {
                                // ... toast options ...
                            });
                        }
                    }
                },
                {
                    label: '취소',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleMemberLimitClick = async (member) => {
        const newLimit = member.memberLimit === 0 ? 1 : 0;
        const message = member.memberLimit === 0 ? (
            <>
                [{member.memberName}] 회원의 <br />계약서 신청 갯수 제한을 해제하시겠습니까?<br />
                (무제한)
            </>
        ) : (
            <>
                [{member.memberName}] 회원의 <br />계약서 신청 갯수를 제한하시겠습니까?<br />
                (최대 5개)
            </>
        );
        await confirmAlert({
            title: '제한 상태 변경',
            message: message,
            buttons: [
                {
                    label: '예',
                    onClick: async () => {
                        try {
                            const response = await fetch(`/api/member/update/${member.memberPk}`, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ memberLimit: newLimit }),
                            });

                            if (response.ok) {
                                setMembers((prevMembers) =>
                                    prevMembers.map((m) =>
                                        m.memberPk === member.memberPk ? { ...m, memberLimit: newLimit } : m
                                    )
                                );
                            } else {
                                throw new Error('회원 제한 상태 변경에 실패했습니다.');
                            }
                        } catch (error) {
                            console.error('회원 제한 상태 변경 실패:', error);
                        }
                    },
                },
                {
                    label: '아니오',
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <React.Fragment>
            <Title>계약 및 회원 관리</Title>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="계약 관리" />
                <Tab label="회원 관리" />
                <Tab label="일별 총매출" />
                <Tab label="월별 총매출" />
            </Tabs>
            {tabValue === 0 && (
                <Box sx={{ maxHeight: 700, overflowY: 'auto', overflowX: 'auto', width: '100%'}}>
                    <Table size="small" sx={{minWidth: 'max-content'}} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>계약 번호</TableCell>
                                <TableCell>계약 시작일</TableCell>
                                <TableCell>계약 종료일</TableCell>
                                <TableCell>계약 기간</TableCell>
                                <TableCell>계약 금액</TableCell>
                                <TableCell>계약서 파일</TableCell>
                                <TableCell>회원 이름</TableCell>
                                <TableCell>회원 정보</TableCell>
                                <TableCell>방 번호</TableCell>
                                <TableCell>bill_id</TableCell>
                                <TableCell>결제 상태</TableCell>
                                <TableCell>결제발송일자</TableCell>
                                <TableCell>결제 내역</TableCell>
                                <TableCell>결제 취소</TableCell>
                                <TableCell>이메일 재발송</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contract.map((row) => (
                                <TableRow key={row.contractPk}>
                                    <TableCell>{row.contractPk}</TableCell>
                                    <TableCell>{row.contractStartDate}</TableCell>
                                    <TableCell>{row.contractEndDate}</TableCell>
                                    <TableCell>{row.contractDuration}개월</TableCell>
                                    <TableCell>{row.contractPrice.toLocaleString()}원</TableCell>
                                    <TableCell>
                                        {row.contractStatus !== '결제 파기' ? createDownloadLink(row.contractPk) : ''}
                                    </TableCell>
                                    <TableCell align="center" onClick={() => handleMemberClick(row.memberPk)}
                                               style={{cursor: 'pointer'}} hover>{row.memberName}
                                    </TableCell>
                                    <TableCell align="right" onClick={() => handleMemberClick(row.memberPk)}
                                               style={{cursor: 'pointer'}}>{row.memberPk}
                                    </TableCell>
                                    <TableCell>{row.contractRoom} </TableCell>
                                    <TableCell>
                                        <Tooltip title={row.contractBillId}>
                                            <span>{row.contractBillId.length > 10 ? `${row.contractBillId.substring(0, 10)}...` : row.contractBillId}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell style={{ color: getStatusColor(row.contractStatus) }}>
                                        {row.contractStatus}
                                    </TableCell>
                                    <TableCell>{formatDate(row.contractBillId)}</TableCell>
                                    <TableCell>
                                        {row.contractStatus === '결제 완료' && (
                                            <MKButton onClick={() => handlePaymentInfoClick(row.contractBillId)}>
                                                결제 내역 확인
                                            </MKButton>
                                        )}
                                    </TableCell>
                                    <TableCell>{row.contractStatus === '결제 완료' ? (
                                        <MKButton onClick={() => cancelPayment(row.contractBillId, row.contractPrice, row.memberName)}>결제 취소</MKButton>
                                    ) : (
                                        ''
                                    )}</TableCell>
                                    <TableCell><MKButton onClick={() => resendEmail(row.contractPk, row.memberName)}>재발송</MKButton></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
            {tabValue === 1 && (
                <Box sx={{ maxHeight: 700, overflowY: 'auto', overflowX: 'auto', width: '100%' }}>
                    {/* 회원 관리 테이블 */}
                    <Table size="small" sx={{ minWidth: 'max-content' }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>회원 번호</TableCell>
                                <TableCell>회원 이름</TableCell>
                                <TableCell>회원 전화번호</TableCell>
                                <TableCell>회원 이메일</TableCell>
                                <TableCell>회원 주소</TableCell>
                                <TableCell>회원 주민등록번호</TableCell>
                                <TableCell>계약서 제한</TableCell>
                                {/* 필요한 회원 정보 열 추가 */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.map((member) => (
                                <TableRow
                                    key={member.memberPk}
                                    hover // 마우스 오버 시 하이라이트
                                    onClick={() => handleMemberLimitClick(member)} // 행 클릭 시 실행할 함수
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{member.memberPk}</TableCell>
                                    <TableCell>{member.memberName}</TableCell>
                                    <TableCell>{member.memberPhonenumber}</TableCell>
                                    <TableCell>{member.memberEmail}</TableCell>
                                    <TableCell>{member.memberAddress}</TableCell>
                                    <TableCell>{member.memberIDnumber}</TableCell>
                                    <TableCell>
                                        <MKButton onClick={() => handleMemberLimitClick(member)}>
                                            {member.memberLimit === 0 ? '제한됨' : '제한 해제'}
                                        </MKButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
            {tabValue === 2 && (
                <Box sx={{ maxHeight: 700, overflowY: 'auto', overflowX: 'auto', width: '100%' }}>
                    <Table size="small" sx={{ minWidth: 'max-content' }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>날짜</TableCell>
                                <TableCell>매출</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(dailySales).map(([date, sales]) => (
                                <TableRow key={date}>
                                    <TableCell>{date}</TableCell>
                                    <TableCell>{sales.toLocaleString()}원</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
            {tabValue === 3 && (
                <Box sx={{ maxHeight: 700, overflowY: 'auto', overflowX: 'auto', width: '100%' }}>
                    <Table size="small" sx={{ minWidth: 'max-content' }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>월</TableCell>
                                <TableCell>총매출</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(monthlySales).map(([month, sales]) => (
                                <TableRow key={month}>
                                    <TableCell>{month}</TableCell>
                                    <TableCell>{sales.toLocaleString()}원</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
            {/*<Link color="primary" href="#" onClick={preventDefault} sx={{mt: 3}}>*/}
            {/*    See more orders*/}
            {/*</Link>*/}
            {isModalOpen && modalType === 'memberInfo' && (
                <MemberModal memberData={selectedMember} onClose={closeModal} />
            )}
            {isModalOpen && modalType === 'paymentInfo' && (
                <PaymentInfoModal paymentData={selectedPaymentInfo} onClose={closeModal} />
            )}
        </React.Fragment>
    );
}