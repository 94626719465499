/* eslint react/prop-types: 0 */
import {motion} from "framer-motion";
import React from "react";
import styled from "styled-components";

function PriceCard({month, price, monthPrice, originalPrice, animate, variants, highlight}) {
    return (
        <Container animate={animate} variants={variants} highlight={highlight}>
            {highlight && (
                <CheckMark>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 11.5L11.5 14L15 10.5" stroke="#6A88BE" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <circle cx="12" cy="12" r="9" stroke="#6A88BE" strokeWidth="2"/>
                    </svg>
                </CheckMark>
            )}
            <MonthText>{month}개월</MonthText>
            <Bottom>
                <PriceText>
                    {/*<PriceTextSpan>{price + "원"}</PriceTextSpan> / 월*/}
                    <MonthPriceTextSpan>{"월 " + monthPrice + "원"}</MonthPriceTextSpan>
                    <PriceTextSpan>{price + "원"}</PriceTextSpan>
                </PriceText>
                <OriginalPriceText>
                    <OriginalPriceTextSpan>{originalPrice}</OriginalPriceTextSpan>원
                </OriginalPriceText>
            </Bottom>
        </Container>
    );
}

export default PriceCard;
const Container = styled(motion.div)`
  width: 230px;
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: space-between;
  padding: 21px 26px 17px;
  position: relative;
  text-align: left;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 0 30px rgba(40, 41, 44, 0.1);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  ${({highlight}) => highlight && `
    background-color: #f0f0ff; /* 배경 색상 변경 */
    border: 2px solid #6A88BE; /* 테두리 색상 변경 */
    box-shadow: 0 0 15px rgba(66, 120, 245, 0.5); /* 그림자 변경 */
  `}
`;
const CheckMark = styled.div`
  position: absolute;
  top: 10px; /* 우상단에 위치하도록 조절 */
  right: 10px; /* 우상단에 위치하도록 조절 */
  /* 필요한 스타일 추가 */
`;

const MonthText = styled.p`
  color: #303030;
  font-size: 26px;
  font-weight: 700;
`;
const Bottom = styled.div``;
const PriceText = styled.p`
  color: #303030;
  font-size: 18px;
  font-weight: 700;
  display: flex; // Flex 컨테이너로 설정
  flex-direction: column; // 자식 요소를 세로로 배열
`;
const PriceTextSpan = styled.span`
  color: #303030;
  font-size: 18px;
  font-weight: 700;
`;

const MonthPriceTextSpan = styled.span`
  font-size: 28px;
  line-height: 36px;
`;

const OriginalPriceText = styled.p`
  color: #b0b7c7;
  font-size: 18px;
  font-weight: 400;
`;
const OriginalPriceTextSpan = styled.span`
  text-decoration: line-through;
`;
