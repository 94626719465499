import React from "react";
import styled from "styled-components";
import PriceCard from "../components/PriceCard";
import useInViewAnimation from "hooks/useInViewAnimation";

function Prices() {
  const getVariant = (index) => ({
    visible: { opacity: 1, transition: { duration: 0.5, delay: index * 0.07 + 0.4 } },
    hidden: { opacity: 0 },
  });
  const [ref, control] = useInViewAnimation(0.5);
  return (
    <Container id="pricingSection" ref={ref}>
      <Contents>
        <Top>
          <Title>요금 안내</Title>
          <Text>
            &quot;보증금 0원&quot;
            <br />
            1회 결제 이후 추가로 결제하실 금액이 없습니다.
          </Text>
        </Top>
        <CardsWrapper>
        <Cards>
          {/*<PriceCard*/}
          {/*  month="3"*/}
          {/*  price="90,000"*/}
          {/*  monthPrice="30,000"*/}
          {/*  originalPrice="90,000"*/}
          {/*  animate={control}*/}
          {/*  initial="hidden"*/}
          {/*  variants={getVariant(0)}*/}
          {/*/>*/}
          <PriceCard
            month="6"
            price="180,000"
            monthPrice="30,000"
            originalPrice="240,000"
            animate={control}
            initial="hidden"
            variants={getVariant(1)}
          />
          <PriceCard
            month="12"
            price="240,000"
            monthPrice="20,000"
            originalPrice="480,000"
            animate={control}
            initial="hidden"
            variants={getVariant(2)}
          />
          <PriceCard
            month="24"
            price="216,000"
            monthPrice="9,000"
            originalPrice="960,000"
            animate={control}
            initial="hidden"
            variants={getVariant(2)}
            highlight={true}
          />
        </Cards>
          <VatNotice>(VAT 별도이며, 정상가격은 월 4만원입니다.)</VatNotice>
        </CardsWrapper>
        <Text2>네 맞습니다.<br /><br /> 실제로 <HighlightText>24개월</HighlightText>이 12개월보다 쌉니다.
          <br /><br />다만, 24개월 가격은 <UnderlineText>예고없이 변동될 수 있습니다.</UnderlineText></Text2>
      </Contents>
    </Container>
  );
}

export default Prices;
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background: #f5f5f7;
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: center;
`;
const Title = styled.p`
  align-items: center;
  color: #303030;
  font-size: 50px;
  font-weight: 700;
  gap: 10px;
  letter-spacing: -0.88px;
  line-height: 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 51px;
  }
`;
const Text2 = styled.p`
  align-items: center;
  color: #303030;
  font-size: 30px;
  font-weight: 700;
  gap: 10px;
  letter-spacing: -0.88px;
  line-height: 30px;
  margin-bottom: 8px;
  margin-top: 70px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
const Text = styled.p`
  color: #949ca8;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.88px;
  line-height: 50px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
const Cards = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const VatNotice = styled.div`
  margin-top: 15px; // 카드와의 간격
  text-align: right; 
  color: #949ca8;
  font-size: 14px;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column; // 이 방식으로 Cards 아래 VAT Notice 배치
`;

const HighlightText = styled.span`
  //color: #6A88BE;
  color: #4971ff;
`;

const UnderlineText = styled.span`
  background-image: linear-gradient(120deg, #4971ff 0%, #8fd3f4 50%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  //color: #4971ff;
  &:hover {
    background-size: 100% 88%;
    color:black;
  }
`;